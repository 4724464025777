import { takeEvery } from 'redux-saga/effects';

import * as trialActionTypes from '../trial/actions';
import * as logActionTypes from '../trialFilters/actions';
import * as cropActionTypes from '../crop/actions';
import * as sectionActionTypes from '../section/actions';
import * as reportActionTypes from '../reportFilters/actions';
import * as productActionTypes from '../productFilters/actions';
import * as profileActionTypes from '../profileFieldGroups/actions';
import * as taskOptionsTypes from '../taskOptions/actions';
import * as historyOptionsTypes from '../historyOptions/actions';
import * as profileInfoTypes from '../profileInfo/actions';
import * as newLogActionTypes from '../newLogOptions/actions';
import * as reportOptionstype from '../reportOptions/actions';

import { getProfileSaga } from './trial';
import { getTrialFiltersSaga } from './trialFilters';
import { getCropOptionsSaga } from './crop';
import { getSectionOptionsSaga } from './section';
import { getReportFiltersSaga } from './reportFilters';
import { getProductFiltersSaga } from './productFilters';
import { getProfileFieldGroupsSaga } from './profileFieldGroups';
import { getTaskOptionsSaga } from './taskOptions';
import { getHistoryOptionsSaga } from './historyOptions';
import { getProfileInfoSaga } from './profileInfo';
import { getNewLogOptionsSaga } from './newLogsOptions';
import { getReportOptionsSaga } from './reportOptions';

export function* watchAuth() {
  yield takeEvery(trialActionTypes.GET_PROFILE, getProfileSaga);
  yield takeEvery(logActionTypes.GET_TRIAL_FILTERS, getTrialFiltersSaga);
  yield takeEvery(cropActionTypes.GET_CROP_OPTIONS, getCropOptionsSaga);
  yield takeEvery(newLogActionTypes.GET_NEW_LOG_OPTIONS, getNewLogOptionsSaga);
  yield takeEvery(
    sectionActionTypes.GET_SECTION_OPTIONS,
    getSectionOptionsSaga
  );
  yield takeEvery(reportActionTypes.GET_REPORT_FILTERS, getReportFiltersSaga);
  yield takeEvery(
    productActionTypes.GET_PRODUCT_FILTERS,
    getProductFiltersSaga
  );
  yield takeEvery(
    profileActionTypes.GET_PROFILE_FIELD_GROUPS,
    getProfileFieldGroupsSaga
  );
  yield takeEvery(taskOptionsTypes.GET_TASK_OPTIONS, getTaskOptionsSaga);
  yield takeEvery(
    historyOptionsTypes.GET_HISTORY_OPTIONS,
    getHistoryOptionsSaga
  );
  yield takeEvery(profileInfoTypes.GET_PROFILE_INFO, getProfileInfoSaga);
  yield takeEvery(reportOptionstype.GET_REPORT_OPTIONS, getReportOptionsSaga);
}
