import { all, put } from 'redux-saga/effects';
import * as actions from '../reportOptions/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getReportOptionsSaga(action) {
  yield put(actions.reportOptionsRequest());
  const actionData = action.actionData;

  const cropsData = {
    url: urls.SEARCH_CROPS,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            in: [action.actionData.trialId],
          },
        },
      },
      navigation: {
        sort: [
          {
            key: 'plantName',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 20,
        },
      },
    },
  };
  const fieldsData = {
    url: urls.REPORT_FIELDS,
    method: 'POST',
    data: {
      trial: {
        id: {
          is: action.actionData.trialId,
        },
      },
    },
  };
  try {
    let [crops, fields] = yield all([
      makeRequest(cropsData, actionData),
      makeRequest(fieldsData, actionData),
    ]);
    crops = crops.data.results.map((crop) => ({
      id: crop.crop.id,
      name: `${crop.plant.name}-${crop.crop.plantDate}`,
    }));

    fields = fields.data.fields.map((field) => ({
      id: field.id,
      name: field.label,
    }));

    yield put(actions.reportOptionsSuccess(crops, fields));
  } catch (error) {
    yield put(actions.reportOptionsFail(error.response));
  }
}
