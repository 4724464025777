export const globalErrorCodes = {
  FORBIDDEN_TRIAL: 'Trial is forbidden',
  FORBIDDEN_CROP: 'Crop is forbidden.',
  FORBIDDEN_PLANT: 'Plant is forbidden.',
  FORBIDDEN_SECTION: 'Section is forbidden.',
  FORBIDDEN_START_FORM: 'Start Form is forbidden.',
  FORBIDDEN_POT_SIZE: 'Pot Size is forbidden',
  FORBIDDEN_PLANT_PROGRAM: 'Plant Program is forbidden.',
  FORBIDDEN_PLANT_CLASS: 'Plant Genus is forbidden.',
  FORBIDDEN_PLANT_SERIES: 'Plant Series is forbidden.',
  FORBIDDEN_BREEDER: 'Breeder is forbidden.',
  FORBIDDEN_MARKET_STATUS: 'Market Status is forbidden.',
  FORBIDDEN_PLANT_COLOR: 'Plant Color is forbidden.',
  FORBIDDEN_PARTNER_TRIAL: 'Partner Trial is forbidden.',
  FORBIDDEN_TRIAL_FIELD_GOAL: 'Trial Field Goal is forbidden.',
  CONFLICT_TRIAL:
    'This trial has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_CROP:
    'This crop has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_LOG:
    'This log has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_PLANT:
    'This plant has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_SECTION:
    'This section has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_PRODUCT_APPLICATION: '',
  CONFLICT_PRODUCT:
    'This product has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_TASK:
    'This task has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_PROFILE_FIELD:
    'This field has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_TRIAL_FIELD:
    'This field has been updated by another user. Please refresh the page and then save your updates.',
  CONFLICT_EMPLOYEE:
    'This employee has been updated by another user. Please refresh the page and then save your updates.',
  BUCKET_NOT_EXIST: 'Destination bucket does not exist',
  NOT_UNIQUE_BUCKET_NAME: 'Bucket name ia already existed',
  CROP_TEMPLATE_REFERENCES_EXIST:
    'This template can not be removed because it is assigned to at least one trial.',
  TASK_ALREADY_COMPLETED: 'Task is already Completed.',
  CANNOT_UPDATE_COMPLETED_TASK: 'Can not update completed task.',
  TASK_PRODUCT_REFERENCES_EXIST:
    'This product can not be removed because it is assigned to at least one task.',
  TASK_PRODUCT_APPLICATION_REFERENCES_EXIST:
    'This application can not be removed because it is assigned to at least one task.',
  TRIAL_FIELD_GOAL_REFERENCE_EXIST:
    ' Trial field can not be updated because it is assigned to at least one goal.',
  EVENT_GOALS_ALREADY_EXISTS: 'Only one event goal can be assigned to plant.',
  EVENT_LOG_ALREADY_EXIST: 'Only one event log can be created.',
  NOT_ADMIN: 'The employee is not an administrator.',
  NOT_EMPLOYEE: 'The employee with such an email does not exists.',
  ALREADY_CONFIRMED: 'This invitation is already confirmed.',
  ALREADY_EXIST: 'You have already shared the trial with the company.',
  EMPLOYEE_FROM_SAME_COMPANY: 'Employee from the same company.',
  INVITE_NOT_ACCEPTED: 'This invite is not accepted.',
  INVITATION_EXPIRED: 'This invitation is expired.',
  CONFIRMATION_FAILED: 'Confirmation is failed.',
  NO_ACTIVE_SUBSCRIPTION: 'No active subscriptions.',
  NOT_ALLOWED_FOR_THIS_SUBSCRIPTION_TYPE:
    'Sharing is not allowed due to subscription restrictions.',
  NO_TEMPLATE: 'Label Template isn`t set.',
  REQUIRED_FIELD: 'This field is required.',
  GOAL_ALREADY_EXIST: 'Goal is already exist.',
};

//ToDo those codes should be moved into 'globalErrorCodes' after 'REFERENCES_EXIST' error code will be replaced with specific error codes.
export const referencesExistCodes = {
  'update-trial': 'Location field cannot be edited because log records exist.',
  'delete-locations':
    'Location can not be removed from locations list because it has a section or it is assigned to trial.',
  'delete-sections':
    'Section can not be removed from sections list because it is assigned to at least one crop.',
  'delete-tasks':
    'Task can not be removed because section or crop are assigned to the task.',
  'update-crop':
    'Plant name and Section fields cannot be edited because log records exist.',
  'delete-crops':
    'Trial crop can not be removed because it has at least one trial log.',
  'update-profile-field':
    'Profile field cannot be edited because trial records exist.',
  'delete-profile-fields':
    'Profile field cannot be removed because trial records exist.',
  'delete-plants':
    'Plant(s) can not be removed because it is assigned to trial(s).',
  'delete-trial-plants':
    'Trial plant can not be removed from trial’s plants list because it is assigned to at least one crop.',
  'delete-employee':
    'Employee can not be removed from employees list because it is assigned to at least one trial.',
  'update-task': 'Section/Crop is assigned to the task',
  'delete-trial-fields':
    'Trial field can not be removed from trial’s fields list because it is used in at least one trial log.',
  'delete-products':
    'Product can not be removed from products list because it is assigned to at least one task.',
};
