export default {
  EXTRACT_PROFILE: 'extract-profile',
  GENERATE_RESET_PASS_LINK: 'generate-reset-pass-link',
  CREATE_TRIAL: 'create-trial',
  DELETE_TRIAL: 'delete-trial',
  UPDATE_TRIAL: 'update-trial',
  DELETE_TRIAL_PLANTS: 'delete-trial-plants',
  DELETE_PLANTS: 'delete-plants',
  EXTRACT_TRIAL: 'extract-trial',
  TRIAL_STATUSES: 'trial-statuses',
  CREATE_LOCATION: 'create-location',
  EXTRACT_LOCATION: 'extract-location',
  UPDATE_LOCATION: 'update-location',
  CREATE_EMPLOYEE: 'create-employee',
  DELETE_EMPLOYEE: 'delete-employee',
  CREATE_EMPLOYEE_PICTURE: 'create-employee-picture',
  UPDATE_EMPLOYEE: 'update-employee',
  SEND_WELCOME_EMAIL: 'send-welcome-email',
  GET_ROLES: 'get-roles',
  EXTRACT_EMPLOYEE: 'extract-employee',
  SEARCH_EMPLOYEES: 'search-employees',
  BROWSE_EMPLOYEES: 'browse-employees',
  CHECK_EMPLOYEES_THUMBNAIL: 'check-employees-thumbnail',
  CREATE_TASK: 'create-task',
  UPDATE_TASK: 'update-task',
  UPDATE_TASK_STATUS: 'update-tasks-status',
  DELETE_TASKS: 'delete-tasks',
  TASK_TYPES: 'task-types',
  TASK_STATUSES: 'task-statuses',
  EXTRACT_TASK: 'extract-task',
  SEARCH_TASKS: 'search-tasks',
  SEARCH_DASHBOARD_TASKS: 'search-dashboard-tasks',
  SEARCH_TRIALS: 'search-trials',
  SEARCH_TASK_SECTIONS_CROPS: 'search-task-sections-crops',
  EXTRACT_TASK_SECTIONS_CROPS: 'extract-task-sections-crops',
  UPDATE_TASK_SECTIONS_CROPS: 'update-task-sections-crops',
  DELETE_TASK_SECTIONS_CROPS: 'delete-task-sections-crops',
  SEARCH_SECTIONS: 'search-sections',
  BROWSE_SECTIONS: 'browse-sections',
  DELETE_SECTIONS: 'delete-sections',
  SEARCH_PRODUCTS: 'search-products',
  EXTRACT_PRODUCT: 'extract-product',
  SEARCH_PRODUCT_APPLICATIONS: 'search-product-applications',
  EXTRACT_PRODUCT_APPLICATION: 'extract-product-application',
  CREATE_PRODUCT: 'create-product',
  UPDATE_PRODUCT: 'update-product',
  DELETE_PRODUCTS: 'delete-products',
  CREATE_PRODUCT_APPLICATION: 'create-product-application',
  UPDATE_PRODUCT_APPLICATION: 'update-product-application',
  DELETE_PRODUCT_APPLICATIONS: 'delete-product-applications',
  PRODUCT_CATEGORIES: 'product-categories',
  PRODUCT_CLASSIFICATIONS: 'product-classifications',
  PRODUCT_MANUFACTURERS: 'product-manufacturers',
  PRODUCT_MIXERS: 'product-mixers',
  PRODUCT_APPLICATION_METHODS: 'product-application-methods',
  PRODUCT_MEASURE_UNITS: 'product-measure-units',
  PRODUCT_MIXER_MEASURE_UNITS: 'product-mixer-measure-units',
  DELETE_PRODUCTS_APPLICATIONS: 'delete-product-applications',
  SUBSCRIPTIONS: 'subscriptions',
  CREATE_SUBSCRIPTION_PORTAL: 'create-subscription-portal',
  COMPANY_PRODUCT_SUBSCRIPTION: 'company-product-subscription',
  CREATE_COMPANY_LOGO: 'create-company-logo',
  PROFILE_FIELD_GROUPS: 'profile-field-groups',
  EXTRACT_PROFILE_FIELD: 'extract-profile-field',
  UPDATE_PROFILE_FIELD: 'update-profile-field',
  CREATE_PROFILE_FIELD: 'create-profile-field',
  START_FORMS: 'startforms',
  POT_SIZES: 'pot-sizes',
  UPDATE_COMPANY: 'update-company',
  EXTRACT_COMPANY: 'extract-company',
  EXTRACT_COMPANY_SETTING: 'extract-company-setting',
  COMPANY_SIGNUP: 'company-signup',
  CREATE_REPLICATION_SETTINGS: 'create-replication-setting',
  UPDATE_REPLICATION_SETTING: 'update-replication-setting',
  UPDATE_RECENTLY_VISITED_TRIAL: 'update-recently-visited-trial',
  RECENTLY_VISITED_TRIALS: 'recently-visited-trials',
  BROWSE_LOCATIONS: 'browse-locations',
  SEARCH_LOCATIONS: 'search-locations',
  DELETE_LOCATIONS: 'delete-locations',
  SEARCH_CROP_TEMPLATES: 'search-crop-templates',
  EXTRACT_CROP: 'extract-crop',
  EXTRACT_CROP_TEMPLATE: 'extract-crop-template',
  GENERATE_CROP_TEMPLATES: 'generate-crop-templates',
  REPORT_PLANT_COMPARISON: 'report-plant-comparison',
  UPDATE_CROP_TEMPLATE: 'update-crop-template',
  SEARCH_CROPS: 'search-crops',
  UPDATE_CROP: 'update-crop',
  CREATE_CROP: 'create-crop',
  CREATE_CROP_TEMPLATE: 'create-crop-template',
  CREATE_DRAFT_CROP_TEMPLATE: 'create-draft-crop-template',
  DELETE_CROPS: 'delete-crops',
  DELETE_CROP_TEMPLATES: 'delete-crop-templates',
  DELETE_PLANT_PDF: 'delete-plant-pdf',
  CREATE_PLANT_IMAGE: 'create-plant-image',
  EXTRACT_PLANT: 'extract-plant',
  CREATE_PLANT: 'create-plant',
  UPDATE_PLANT: 'update-plant',
  PARAMETERS_PLANT_COMPARISON: 'parameters-plant-comparison',
  CHECK_PLANTS_THUMBNAIL: 'check-plants-thumbnail',
  IMPORT_PLANTS: 'import-plants',
  EXTRACT_SECTION: 'extract-section',
  CREATE_SECTION: 'create-section',
  UPDATE_SECTION: 'update-section',
  SECTION_TYPES: 'sectiontypes',
  ZONES: 'zones',
  PROGRAMS: 'programs',
  CLASSES: 'genus',
  SERIES: 'series',
  SPECIES: 'species',
  BREEDERS: 'breeders',
  COLORS: 'colors',
  MARKET_STATUSES: 'market-statuses',
  HARDINESS_ZONES: 'hardiness-zones',
  SEARCH_APPLICATION_HISTORY: 'search-application-history',
  SEARCH_APPLICATION_HISTORY_PRODUCTS: 'search-application-history-products',
  SEARCH_APPLICATION_SECTIONS: 'search-application-history-sections',
  SEARCH_PLANTS: 'search-plants',
  ADD_TRIAL_PLANTS: 'add-trial-plants',
  ADD_TRIAL_FIELDS: 'add-trial-fields',
  SEARCH_PROFILE_FIELDS: 'search-profile-fields',
  DELETE_PROFILE_FIELDS: 'delete-profile-fields',
  SEARCH_TRIAL_PLANTS: 'search-trial-plants',
  UPDATE_TRIAL_PLANT: 'update-trial-plant',
  PATCH_TRIAL_FIELD: 'patch-trial-field',
  DELETE_TRIAL_FIELDS: 'delete-trial-fields',
  SEND_CONTACT_SUPPORT_EMAIL: 'support/send-contact-support-email',
  SEND_SALES_CONTACT_SUPPORT_EMAIL: 'support/send-sales-contact-support-email',
  GENERATE_CROP_TEMPLATES_PDF: 'generate-crop-templates/pdf',
  UPLOAD_CROP_TEMPLATE_IMAGE: 'upload-crop-template-image',
  BATCH_PLANT_UPDATE: 'batch-plant-update',
  ANALYTIC_COMPANY: 'analytic/company',
  ANALYTIC_FINANCE: 'analytic/finance',
  ANALYTIC_COMPANY_EXCEL: 'analytic/company/excel',
  SEARCH_SHARED_TRIALS: 'search-shared-trials',
  SHARE_TRIAL: 'share-trial',
  UNLINK_SHARED_TRIAL: 'unlink-shared-trial',
  EXPORT_LOGS: 'export-logs',
  EXPORT_LOG_STATUS: 'export-logs-status',
  CHECK_LOGS_THUMBNAIL: 'check-logs-thumbnail',
  SEARCH_LOGS: 'search-logs',
  EXTRACT_LOG: 'extract-log',
  LOG_FREQUENCIES: 'logfrequencies',
  SEARCH_TRIAL_FIELDS: 'search-trial-fields',
  UPDATE_LOG: 'update-log',
  DELETE_LOGS: 'delete-logs',
  UPDATE_LOG_IMAGE: 'update-log-image',
  DELETE_LOG_IMAGES: 'delete-log-images',
  IMPORT_CROPS: 'import-crops',
  EXPORT_CROPS: 'export-crops',
  BATCH_CROP_UPDATE: 'batch-crop-update',
  DISABLE_COMPANY_MTM: 'disable-company-mtm',
  ENABLE_COMPANY_MTM: 'enable-company-mtm',
  EXTRACT_COMPANY_MTM_SECRET: 'extract-company-mtm-secret',
  REVOKE_COMPANY_MTM_SECRET: 'revoke-company-mtm-secret',
  REVEAL_COMPANY_MTM_SECRET: 'reveal-company-mtm-secret',
  RESET_PASSWORD_FROM_EMAIL: 'reset-password-from-email',
  VERIFY_COMPANY_EMAIL: 'verify-company-email',
  SEND_COMPANY_EMAIL_VERIFICATION: 'send-company-email-verification',
  CREATE_TRIAL_FIELD_GOALS: 'create-trial-field-goals',
  UPDATE_TRIAL_FIELD_GOALS: 'update-trial-field-goals',
  SEARCH_TRIAL_FIELD_GOALS: 'search-trial-field-goals',
  SEARCH_TRIAL_FIELDS_GOAL: 'search-trial-fields-goal',
  SEARCH_TRIAL_PLANTS_GOAL: 'search-trial-plants-goal',
  DELETE_TRIAL_FIELD_GOALS: 'delete-trial-field-goals',
  EXTRACT_TRIAL_FIELD_GOAL: 'extract-trial-field-goal',
  SEARCH_PLANT_GOALS_RESULTS: 'search-plant-goal-results',
  CREATE_LOG: 'create-log',
  CREATE_LOG_IMAGE: 'create-log-image',
  PUBLISH_LOG: 'publish-log',
  REPORT_GENERATE: 'reports/crop-profile',
  REPORT_FIELDS: 'reports/crop-profile/params',
};
