import { all, put } from 'redux-saga/effects';
import * as actions from '../newLogOptions/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';

export function* getNewLogOptionsSaga(action) {
  yield put(actions.newLogOptionsRequest());

  const actionData = action.actionData;

  const plantData = {
    url: urls.SEARCH_TRIAL_PLANTS,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            is: action.trialId,
          },
        },
      },
      navigation: {
        sort: [
          {
            key: 'name',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 1000,
        },
      },
    },
  };

  const fieldData = {
    url: urls.SEARCH_TRIAL_FIELDS,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            in: [action.trialId],
          },
        },
      },
      navigation: {
        sort: [
          {
            key: 'order',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 1000,
        },
      },
    },
  };

  try {
    let [plants, fields] = yield all([
      makeRequest(plantData, actionData),
      makeRequest(fieldData, actionData),
    ]);
    yield put(
      actions.newLogOptionsSuccess(plants.data.results, fields.data.results)
    );
  } catch (error) {
    yield put(actions.newLogOptionsFail(error.response));
  }
}
