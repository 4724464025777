import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { uniqBy } from 'lodash';
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import DatePicker from 'datepicker-special-week-numbers';

import { setPopUp } from '../../../../reduxStore/popUp/actions';
import Footer from '../../../Common/Footer/Footer';
import { useRequest, urls } from '../../../Common/ApiServices';
import LogImages from '../LogImages/LogImages';
import Header from './Header/Header';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import {
  errorOptions,
  sortableFields,
  getInitialLogParams,
  mandatoryColumns,
  nonEditableColumns,
} from './constants';
import { whileTimers } from '../../constants';
import {
  dateFormat,
  datePickerTimeFormat,
  timeFormat,
  popUp,
  showDetailsFields,
} from '../../../Common/globalConstants';
import { wait } from '../../../Common/utils';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ShowDetails from '../../../Common/showDetails/showDetails';
import {
  checkImageStatus,
  getLogParams,
  getBreadCrumbItems,
  getNewLogParams,
  getMappedLogs,
} from '../../utils';

import styles from './LogList.module.scss';

const LogList = ({
  newFilters,
  isCropLogs,
  trialFields,
  fieldsTypes,
  trialId,
}) => {
  const cropId = localStorage.getItem('cropId');
  const trialName = localStorage.getItem('trialName');
  const cropName = localStorage.getItem('cropName');
  const entity = isCropLogs
    ? { name: 'crop', id: cropId }
    : { name: 'trial', id: trialId };

  const [logs, setLogs] = useState([]);
  const [initialLogs, setInitialLogs] = useState([]);
  const [editing, setEditing] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [columns, setColumns] = useState(mandatoryColumns);
  const [columnsTypes, setColumnsTypes] = useState({
    image: null,
    checkbox: null,
  });
  const [filters, setFilters] = useState({});
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [logToEdit, setLogToEdit] = useState(null);
  const [logIsEdited, setLogIsEdited] = useState(false);
  const [logToDelete, setLogToDelete] = useState(null);
  const [deleteLogDialog, setDeleteLogDialog] = useState(false);
  const [logIsDeleted, setLogIsDeleted] = useState(false);
  const [groupedBy, setGroupedBy] = useState({
    value: null,
    column: 'logWeek',
  });
  const [sort, setSort] = useState({ field: 'logDate', order: -1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [frozenWidth, setFrozenWidth] = useState(250);
  const [editedImageId, setEditedImageId] = useState('');
  const [imageIsUpdated, setImageIsUpdated] = useState(false);
  const [imageIsCreated, setImageIsCreated] = useState(false);
  const [isChecking, setChecking] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { error, isLoading, sendRequest } = useRequest({});

  const permissions = useSelector((state) => state.permissions.permissions);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const subscription = useSelector((state) => state.subscription.uuid);
  const company = useSelector((state) => state.profileInfo?.company);

  const checkThumbnail = async (logId) => {
    let imageStatus;
    let isReady = false;
    let attempt = 0;
    while (attempt < whileTimers.maxAttempt) {
      const requestData = {
        url: urls.CHECK_LOGS_THUMBNAIL,
        method: 'POST',
        data: { log: { id: { in: [logId] } } },
      };
      const response = await sendRequest(requestData);
      imageStatus = response.data;

      if (checkImageStatus(imageStatus.logs[0].images)) {
        isReady = true;
        setImageIsCreated(false);
        setImageIsUpdated(false);
        break;
      } else {
        await wait(whileTimers.loopDelay);
        attempt += 1;
      }
    }
    if (attempt === whileTimers.maxAttempt) {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: 'Failed to create a thumbnail.',
          life: 5000,
        })
      );
      isReady = true;
    }
    return isReady;
  };

  const fetchLogs = async () => {
    let customCols = [];
    let imageCols = [];
    let checkboxCols = [];
    let thumbnailIsReady = true;
    if (imageIsUpdated || imageIsCreated) {
      setChecking(true);
      thumbnailIsReady = await checkThumbnail(logToEdit.id);
    }
    if (thumbnailIsReady) {
      const requestData = {
        url: urls.SEARCH_LOGS,
        method: 'POST',
        data: getLogParams(
          groupedBy,
          sort,
          filters,
          pagination,
          entity,
          logToEdit,
          exporting,
          selectedColumns
        ),
      };
      const response = await sendRequest(requestData);
      setImageIsUpdated(false);
      const newLogs = getMappedLogs(
        response.data.results,
        customCols,
        imageCols,
        checkboxCols
      );

      if (logToEdit) {
        const changedLogs = logs.map((log) => {
          if (log.id === logToEdit.id) {
            return newLogs[0];
          } else {
            return log;
          }
        });
        setLogs(changedLogs);
      } else {
        customCols = uniqBy(customCols, 'field').sort((a, b) =>
          a.header < b.header ? -1 : 1
        );
        const allColumns = [...mandatoryColumns, ...customCols];
        setInitialLogs(newLogs);
        const copiedLogs = cloneDeep(newLogs);
        if (editedImageId) {
          const fieldName = trialFields?.find(
            (field) => field.id === editedImageId
          ).name;
          const changedLog = copiedLogs.find((log) => log.id === logToEdit.id);
          const newChangedLog = {
            ...logs.find((log) => log.id === logToEdit.id),
          };
          newChangedLog[fieldName] = changedLog[fieldName];
          copiedLogs.forEach((item, index) => {
            if (item.id === logToEdit.id) {
              copiedLogs[index] = newChangedLog;
            }
          });
          setLogToEdit(newChangedLog);
        }
        const copiedLogs2 = copiedLogs.map((log) => {
          const newLog = log;
          newLog.createDateGroup = moment(log.createDate).valueOf();
          return newLog;
        });
        setLogs(copiedLogs2);
        setColumnsTypes({
          image: [...new Set(imageCols)],
          checkbox: [...new Set(checkboxCols)],
        });
        if (selectedColumns === columns) setSelectedColumns(allColumns);
        setColumns(allColumns);
        setTotalRecords(response.data.page.total);
      }
    }
    setLogIsDeleted(false);
    setLogIsEdited(false);
    setEditedImageId('');
    setChecking(false);
  };

  const patchLog = async () => {
    for (let field of Object.keys(logToEdit)) {
      let values = trialFields?.find((field_) => field_.name === field)?.values;
      if (values && !logToEdit[field].id) {
        let fieldId = trialFields?.find(
          (trialField) => trialField.name === field
        ).id;
        logToEdit[field] = {
          id: fieldId,
          values: logToEdit[field].map((log) => log.id),
        };
      }
    }

    const requestData = {
      url: urls.UPDATE_LOG,
      method: 'POST',
      data: getNewLogParams(logToEdit, fieldsTypes, trialFields),
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Log for ${logToEdit.cropPlantName} was updated.`,
          life: 5000,
        })
      );
      await fetchLogs(logToEdit.id);
    } else {
      setLogs(JSON.parse(JSON.stringify(initialLogs)));
      setLogIsEdited(false);
      setEditedImageId('');
      setChecking(false);
    }
    setLogToEdit(null);
  };

  const updateFilters = (newFilters) => {
    if (!newFilters) return null;
    const { genuses, logDate, programs, sections, series, plantDate } =
      newFilters;
    const newGenuses = genuses ? genuses.map(({ id }) => id) : null;
    const newPrograms = programs ? programs.map(({ id }) => id) : null;
    const newSections = sections ? sections.map(({ id }) => id) : null;
    const newSeries = series ? series.map(({ id }) => id) : null;

    let newLogDateFrom = null;
    let newLogDateTo = null;
    if (logDate) {
      newLogDateFrom = moment
        .utc(logDate[0])
        .add(1, 'days')
        .startOf('day')
        .format();
      const dateTo = logDate[1] || logDate[0];
      newLogDateTo = moment.utc(dateTo).add(1, 'days').endOf('day').format();
    }

    let newPlantDateFrom = null;
    let newPlantDateTo = null;
    if (plantDate) {
      newPlantDateFrom = moment(plantDate[0]).format(
        'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
      );
      newPlantDateTo = newPlantDateFrom;
      if (plantDate[1]) {
        newPlantDateTo = moment(plantDate[1]).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        );
      }
    }
    setFilters((oldFilters) => {
      let filters = cloneDeep(oldFilters);
      newGenuses
        ? (filters.genus = { id: { in: newGenuses } })
        : delete filters.genus;

      newPrograms
        ? (filters.program = { id: { in: newPrograms } })
        : delete filters.program;

      newSections
        ? (filters.section = { id: { in: newSections } })
        : delete filters.section;

      newSeries
        ? (filters.series = { id: { in: newSeries } })
        : delete filters.series;

      newLogDateFrom
        ? (filters.log = {
            createDate: { from: newLogDateFrom, to: newLogDateTo },
          })
        : delete filters.log;

      newPlantDateFrom
        ? (filters.plantDate = { from: newPlantDateFrom, to: newPlantDateTo })
        : delete filters.plantDate;

      return filters;
    });
  };

  const setMultiSortMeta = (event) => {
    let newSort = {
      field: event.multiSortMeta[0].field,
      order: event.multiSortMeta[0].order,
    };
    setSort(newSort);
    event.multiSortMeta = [{ field: groupedBy.column, order: 1 }, newSort];
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onPageSelect = ({ first, rows }) => {
    setPagination({ first, rows });
  };

  const onRowEditInit = (event) => {
    setLogToEdit({ ...event.data });
    setEditing(true);
  };

  const onRowEditSave = () => {
    setLogIsEdited(true);
    setEditing(false);
  };

  const onRowEditCancel = () => {
    setLogIsEdited(false);
    const newLogs = JSON.parse(JSON.stringify(initialLogs));
    setLogs([...newLogs]);
    setEditing(false);
    setLogToEdit(null);
    setLogIsEdited(false);
  };

  const onEditorValueChange = (fieldKey, props, value, field) => {
    let updatedLogs = [...props.value];
    if (fieldsTypes[props.field] === 'TIME') {
      if (value) {
        updatedLogs[props.rowIndex][props.field] = moment(
          `2022.01.01 ${value}`
        ).format(timeFormat[props.rowData.datePattern]);
      } else {
        delete updatedLogs[props.rowIndex][field];
      }
    } else if (fieldsTypes[props.field] === 'DATE') {
      if (value) {
        updatedLogs[props.rowIndex][props.field] = moment(
          value,
          dateFormat[props.rowData.datePattern]
        ).format(dateFormat[props.rowData.datePattern]);
      } else {
        delete updatedLogs[props.rowIndex][field];
      }
    }
    const newLogToEdit = { ...logToEdit, [field]: value };
    updatedLogs[props.rowIndex][props.field] = value;
    setLogToEdit(newLogToEdit);

    setLogs(updatedLogs);
  };

  const inputTextEditor = (fieldKey, props, field, type) => {
    let defaultWidth = '100px';
    let placeholder = '';
    if (field === 'ownerId') defaultWidth = '130px';
    if (field === 'note' || type === 'DATE_TIME') defaultWidth = '150px';
    if (!nonEditableColumns.includes(field)) {
      return (
        <InputText
          type="text"
          value={logToEdit[field] || ''}
          style={{ width: defaultWidth }}
          onChange={(e) =>
            onEditorValueChange(fieldKey, props, e.target.value, field, type)
          }
          placeholder={placeholder}
        />
      );
    } else {
      return <>{props.rowData[field]}</>;
    }
  };

  const timeEditor = (fieldKey, props, field) => {
    let time = props.rowData[field]
      ? new Date(`2022.01.01 ${props.rowData[field]}`)
      : '';
    return (
      <div className={styles.timeEditor}>
        <DatePicker
          id="time"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          selected={time}
          dateFormat={datePickerTimeFormat[props.rowData.datePattern]}
          timeFormat={datePickerTimeFormat[props.rowData.datePattern]}
          clerable={true}
          onChange={(val) => {
            const newVal = val ? val : undefined;
            onEditorValueChange(
              fieldKey,
              props,
              newVal ? moment(newVal).format('HH:mm:ss') : undefined,
              field
            );
          }}
        />
      </div>
    );
  };

  const checkboxEditor = (fieldKey, props, field) => {
    return (
      <div className={styles.checkboxEditor}>
        <Checkbox
          checked={!(props.rowData[field] === 'false' || !props.rowData[field])}
          onChange={(e) =>
            onEditorValueChange(fieldKey, props, e.checked, field)
          }
        />
      </div>
    );
  };

  const dropdownEditor = (fieldKey, props, field) => {
    const fieldOptions = trialFields?.find(
      (field_) => field_.name === field
    )?.values;
    let arrayOfSelectedValues =
      fieldOptions.find((option) => option.name === props.rowData[field]) ||
      props.rowData[field];
    return (
      <Dropdown
        className={styles.dropdownEditor}
        value={
          arrayOfSelectedValues?.length > 0 ? arrayOfSelectedValues[0] : ''
        }
        options={fieldOptions}
        optionLabel="name"
        onChange={(e) =>
          onEditorValueChange(fieldKey, props, [e.target.value], field)
        }
      />
    );
  };

  const multiSelectEditor = (fieldKey, props, field) => {
    const fieldOptions = trialFields?.find(
      (field_) => field_.name === field
    )?.values;
    return (
      <MultiSelect
        className={classNames(styles.multiselect, styles.multiSelectEditor)}
        value={
          fieldOptions.find((option) => option.name === props.rowData[field]) ||
          props.rowData[field]
        }
        options={fieldOptions}
        optionLabel="name"
        onChange={(e) =>
          onEditorValueChange(fieldKey, props, e.target.value, field)
        }
      />
    );
  };

  const imageEditor = (fieldKey, props, field) => {
    let fieldId = trialFields?.find((field_) => field_.name === field)?.id;
    return (
      <LogImages
        images={props.rowData[field]}
        header={props.header}
        isEditing={true}
        logId={props.rowData.id}
        fieldId={fieldId}
        setEditedImageId={setEditedImageId}
        setImageIsUpdated={setImageIsUpdated}
        setImageIsCreated={setImageIsCreated}
        imageIsUpdated={imageIsUpdated}
        imageIsCreated={imageIsCreated}
      />
    );
  };

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'plant') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const confirmDeleteLog = (log) => {
    setLogToDelete(log);
    setDeleteLogDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return isTrialTracker ? null : (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className={classNames(
            'p-button-rounded p-button-warning',
            editing ? styles.hidden : styles.visible
          )}
          onClick={() => confirmDeleteLog(rowData)}
        />
      </React.Fragment>
    );
  };

  const hideDeleteLogDialog = () => {
    setDeleteLogDialog(false);
  };

  const deleteLog = async () => {
    setDeleteLogDialog(false);
    const logParams = {
      id: {
        in: [logToDelete.id],
      },
    };

    const requestData = {
      url: urls.DELETE_LOGS,
      method: 'POST',
      data: logParams,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setLogIsDeleted(true);
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: `Log for ${logToDelete.cropPlantName} was deleted.`,
          life: 5000,
        })
      );
    }
  };

  const logImages = (images, header) => {
    return <LogImages images={images} header={header} />;
  };

  const checkboxBodyTemplate = (isChecked) => {
    if (isChecked === 'true' || isChecked === 'false') {
      return <Checkbox disabled checked={isChecked === 'true'} />;
    }
  };

  const dropdownBodyTemplate = (value) => {
    return (
      <>
        {!value
          ? ''
          : Array.isArray(value)
          ? value.map((v) => v.name).join()
          : value.name}
      </>
    );
  };

  const textTemplate = (rowData, field) => {
    let res;
    if (logToEdit && logToEdit.id === rowData.id) {
      res = logToEdit[field];
    } else {
      res = rowData[field];
    }
    if (typeof res === 'object' && res !== null) {
      return res?.name;
    } else {
      return res;
    }
  };

  const columnComponents = selectedColumns.map((col, index) => {
    let defaultStyle = { width: '120px', height: '48px' };
    let sortField = col.field;
    if (col.field === 'note' || col.type === 'DATE_TIME') {
      defaultStyle = { width: '170px', height: '48px' };
    }
    if (col.field === 'ownerId') {
      defaultStyle = { width: '150px', height: '48px' };
      sortField = 'ownerLastName';
    }
    if (columnsTypes.image?.includes(col.field)) {
      return (
        <Column
          className="p-dt-tooltip"
          key={index}
          columnKey={index}
          field={col.field}
          header={col.header}
          headerStyle={{ width: '240px', height: '48px' }}
          bodyStyle={{ height: '70px', padding: 0 }}
          body={(rowData) => logImages(rowData[col.field], col.header)}
          editor={(props) => imageEditor('fields', props, col.field)}
        />
      );
    } else if (columnsTypes.checkbox?.includes(col.field)) {
      return (
        <Column
          key={index}
          columnKey={index}
          field={col.field}
          header={col.header}
          headerStyle={{
            width: '120px',
            height: '48px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          bodyStyle={{ height: '70px' }}
          body={(rowData) => checkboxBodyTemplate(rowData[col.field])}
          editor={(props) => checkboxEditor('fields', props, col.field)}
        />
      );
    } else if (
      trialFields?.find((field_) => field_.name === col.field)?.values
    ) {
      return (
        <Column
          key={index}
          columnKey={index}
          field={col.field}
          header={col.header}
          headerStyle={{
            width: '130px',
            height: '48px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          bodyStyle={{ height: '70px' }}
          body={(rowData) => dropdownBodyTemplate(rowData[col.field])}
          editor={(props) =>
            trialFields?.find((field) => field.name === col.field).multiselect
              ? multiSelectEditor('fields', props, col.field)
              : dropdownEditor('fields', props, col.field)
          }
        />
      );
    } else if (col.type === 'TIME') {
      return (
        <Column
          key={index}
          columnKey={index}
          field={col.field}
          className="p-text-nowrap p-text-truncate"
          header={col.header}
          headerStyle={defaultStyle}
          bodyStyle={{ height: '70px' }}
          editor={(props) => timeEditor('fields', props, col.field, col.type)}
        />
      );
    } else if (col.field === 'note' && col.field === 'general_note') {
      return (
        <Column
          key={index}
          columnKey={col.field}
          field={col.field}
          className="p-dt-tooltip p-text-nowrap p-text-truncate"
          sortField={sortableFields[sortField] || sortField}
          sortable={Object.keys(sortableFields).includes(col.field) && !editing}
          header={col.header}
          headerStyle={defaultStyle}
          bodyStyle={{ height: '70px' }}
          body={(rowData) => ShowDetails(rowData[showDetailsFields.NOTE])}
          editor={(props) =>
            inputTextEditor('fields', props, col.field, col.type)
          }
        />
      );
    } else {
      return (
        <Column
          key={index}
          columnKey={index}
          field={col.field}
          className=" p-text-nowrap p-text-truncate"
          sortField={sortableFields[sortField] || sortField}
          sortable={Object.keys(sortableFields).includes(col.field) && !editing}
          header={col.header}
          headerStyle={defaultStyle}
          bodyStyle={{ height: '70px' }}
          body={(rowData) => textTemplate(rowData, col.field)}
          editor={(props) =>
            inputTextEditor('fields', props, col.field, col.type)
          }
        />
      );
    }
  });

  const editColumn = isEditingAllowed && (
    <Column
      className={editing ? 'rowEditor-editButton-hidden ' : ''}
      rowEditor={!isTrialTracker}
      header="Edit"
      headerStyle={{ width: '80px', height: '48px' }}
      bodyStyle={{ height: '70px' }}
    />
  );

  const removeColumn = isEditingAllowed && (
    <Column
      field="remove"
      header="Remove"
      headerStyle={{ width: '70px' }}
      body={actionBodyTemplate}
    />
  );

  const groupHeaderTemplate = (data) => (
    <span className={styles.groupByName}>{data[groupedBy.column]}</span>
  );

  useEffect(() => {
    if (permissions && company && !isEditingAllowed) {
      if (
        company?.id === logs[0]?.companyId &&
        (permissions.includes('ppt:manage:company') ||
          permissions.includes('ppt:manage:log'))
      ) {
        setIsEditingAllowed(true);
      }
    }
  }, [permissions, company, logs, isEditingAllowed]);

  useEffect(() => {
    updateFilters(newFilters);
  }, [newFilters]);

  useEffect(() => {
    fetchLogs();
  }, [sort, pagination, filters, groupedBy]);

  useEffect(() => {
    if (logIsDeleted || editedImageId) {
      fetchLogs();
      setEditing(false);
    }
  }, [logIsDeleted, editedImageId]);

  useEffect(() => {
    logIsEdited && patchLog();
  }, [logIsEdited]);

  useEffect(() => {
    if (error && error.response.data.errors) {
      error.response.data.errors.forEach((err) => {
        dispatch(
          setPopUp({
            severity: popUp.severities.ERROR,
            summary: popUp.summary.ERROR,
            detail: `${err.fieldName} ${errorOptions[err.code]}.`,
            life: 5000,
          })
        );
      });
    }
  }, [dispatch, error]);

  if (exporting) {
    return (
      <div className="generic-list-message export">
        <Tooltip target=".custom-target" />
        <h2 className="p-text-center">
          Please wait while the logs are exported...
        </h2>
        <ProgressBar
          mode="indeterminate"
          className={styles.progressBar}
          color="#0097A7"
        />
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.logList, 'list-generic')}>
        <BreadCrumb
          items={getBreadCrumbItems(
            history,
            isCropLogs,
            trialName,
            trialId,
            cropId,
            cropName,
            location
          )}
        />
        <PromptIfDirty dirty={editing} />
        <DataTable
          className={classNames(
            'table-generic p-datatable-sm logs',
            styles.customTableStyle
          )}
          reorderableColumns
          value={logs}
          header={
            <Header
              groupedBy={groupedBy}
              setGroupedBy={setGroupedBy}
              editing={editing}
              selectedColumns={selectedColumns}
              columns={columns}
              onColumnToggle={onColumnToggle}
              subscription={subscription}
              exporting={exporting}
              setExporting={setExporting}
              logToEdit={logToEdit}
              sort={sort}
              filters={filters}
              pagination={pagination}
              entity={entity}
            />
          }
          rowGroupMode="subheader"
          groupField={groupedBy.column}
          resizableColumns
          columnResizeMode="expand"
          sortMode="multiple"
          multiSortMeta={[
            {
              field:
                groupedBy.column === 'createDate'
                  ? 'createDateGroup'
                  : groupedBy.column,
              order: -1,
            },
            { field: sort.field, order: sort.order },
          ]}
          sortField={sort.field}
          sortOrder={sort.order}
          onSort={(e) => setMultiSortMeta(e)}
          scrollable
          emptyMessage=""
          frozenWidth={frozenWidth + 'px'}
          onColumnResizeEnd={(event) => changeFrozenWidth(event)}
          loading={isLoading || isChecking}
          rowGroupHeaderTemplate={groupHeaderTemplate}
          rowGroupFooterTemplate={() => null}
          editMode="row"
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onRowEditSave}
          onRowEditInit={onRowEditInit}
        >
          <Column
            className="p-text-nowrap p-text-truncate"
            field="cropPlantName"
            header="Plant"
            headerStyle={{ width: '250px', height: '48px' }}
            bodyStyle={{ height: '70px' }}
            key="plant"
            columnKey="plant"
            body={(rowData) => textTemplate(rowData, 'cropPlantName')}
            frozen
            sortable={!editing}
          />
          {columnComponents}
          {editColumn}
          {removeColumn}
        </DataTable>
        <Footer
          totalRecords={totalRecords}
          initParams={getInitialLogParams(entity)}
          params={getLogParams(
            groupedBy,
            sort,
            filters,
            pagination,
            entity,
            logToEdit,
            exporting,
            selectedColumns
          )}
          pagination={pagination}
          type="log"
          onPageSelect={onPageSelect}
        />
        <DeleteDialog
          deleteLogDialog={deleteLogDialog}
          hideDeleteLogDialog={hideDeleteLogDialog}
          logToDelete={logToDelete}
          deleteLog={deleteLog}
        />
      </div>
    );
  }
};

export default withRouter(LogList);
