import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { useFormik } from 'formik';
import classNames from 'classnames';
import moment from 'moment';

import { clearLogOptions } from '../../../../reduxStore/newLogOptions/actions';
import { fieldTypesOptions, popUp } from '../../../Common/globalConstants';
import { urls, useRequest } from '../../../Common/ApiServices';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { modals } from '../constants';
import {
  getDefaultValues,
  getFieldNameIds,
  getNewLogFields,
  getValidationData,
} from '../../utils';

import DynamicField from './DynamicField/DynamicField';

import styles from './NewLog.module.scss';

const NewLogForm = ({ displayModal, setDisplayModal, crops, trialFields }) => {
  const [isRequiredFields, setIsRequiredFields] = useState(false);
  const [logId, setLodId] = useState('');
  const [log, setLog] = useState({});

  const dispatch = useDispatch();
  const { error, sendRequest } = useRequest({});

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const cropOptions = crops
    ? crops.map((crop) => ({
        name: crop.cropNumber,
        id: crop.id,
      }))
    : [];

  const publishLog = async (logId) => {
    const requestData = {
      url: urls.PUBLISH_LOG,
      method: 'POST',
      data: {
        log: {
          id: logId,
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Log was created successfully.',
          life: 5000,
          sticky: null,
        })
      );
    }
    onHide();
  };

  const createLog = async (data) => {
    const requestData = {
      url: urls.CREATE_LOG,
      method: 'POST',
      data: data,
    };
    const response = await sendRequest(requestData);
    if (response) {
      setLodId(response.data.log.id);
    }
  };

  const updateLog = async (data) => {
    const requestData = {
      url: urls.UPDATE_LOG,
      method: 'POST',
      data: data,
    };
    const response = await sendRequest(requestData);
    if (response) {
      await publishLog(logId);
    }
  };

  const createLogImage = useCallback(
    async (images, field) => {
      const fieldIds = getFieldNameIds(trialFields);
      const formData = new FormData();
      formData.append('logId', logId);
      formData.append('fieldId', fieldIds[field]);
      formData.append('file', images);

      const requestData = {
        url: urls.CREATE_LOG_IMAGE,
        method: 'POST',
        data: formData,
      };

      await sendRequest(requestData);
    },
    [trialFields, logId]
  );

  const extractLog = async (logId) => {
    const data = {
      log: {
        id: {
          is: logId,
        },
      },
    };
    const requestData = {
      url: urls.EXTRACT_LOG,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setLog(response.data.log);
    }
  };

  const formik = useFormik({
    initialValues: getDefaultValues(trialFields),
    enableReinitialize: true,
    validationSchema: getValidationData(trialFields),
    onSubmit: async (values) => {
      const data = {
        log: {
          id: log.id,
          note: values.note,
          fields: getNewLogFields(formik.values, trialFields),
          versionNumber: log.version.number,
        },
      };
      await updateLog(data);
    },
  });

  const onHide = () => {
    dispatch(clearLogOptions());
    setDisplayModal('');
    formik.resetForm();
  };

  useEffect(() => {
    if (error) {
      setDisplayModal(false);
      onHide();
    }
  }, [error]);

  useEffect(() => {
    if (formik.values.crop && formik.values.eventType) {
      setIsRequiredFields(true);
      const data = {
        parent: {
          crop: {
            id: formik.values.crop.id,
          },
        },
        log: {
          createDate: moment().toISOString(),
          fields: [],
          eventType: formik.values.eventType.name,
        },
      };
      createLog(data);
    }
  }, [formik.values.crop, formik.values.eventType]);

  useEffect(() => {
    logId && extractLog(logId);
  }, [logId]);

  return (
    <Fragment>
      <Dialog
        className={styles.logFormDialog}
        header="New Log"
        visible={displayModal === modals.ADD_NEW}
        onHide={() => confirm(formik.dirty)}
      >
        <Divider />
        <PromptIfDirty dirty={formik.dirty} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="crop" className="p-col-12 p-md-3 p-text-bold">
                Crop*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="crop"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'crop' } });
                  }}
                  value={formik.values.crop}
                  aria-describedby="crop-invalid"
                  className={classNames(
                    formik.errors.crop && formik.touched.crop && 'p-invalid'
                  )}
                  options={cropOptions}
                  filter
                  optionLabel="name"
                />
                {formik.touched.crop && formik.errors.crop ? (
                  <small id="crop-invalid" className="p-error p-d-block">
                    {formik.errors.crop}
                  </small>
                ) : (
                  <small>Crop Number is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="eventType"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Event Type*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="eventType"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'eventType' } });
                  }}
                  value={formik.values.eventType}
                  aria-describedby="eventType-invalid"
                  className={classNames(
                    formik.errors.eventType &&
                      formik.touched.eventType &&
                      'p-invalid'
                  )}
                  options={fieldTypesOptions}
                  filter
                  optionLabel="name"
                />
                {formik.touched.eventType && formik.errors.eventType ? (
                  <small id="eventType-invalid" className="p-error p-d-block">
                    {formik.errors.eventType}
                  </small>
                ) : (
                  <small>Field Type is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3 p-text-bold">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  id="note"
                  className={classNames(
                    formik.touched.note && formik.errors.note && 'p-invalid'
                  )}
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'note' } });
                  }}
                  disabled={!isRequiredFields}
                  value={formik.values.note}
                />
                {formik.touched.note && formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : (
                  <small>Note is required.</small>
                )}
              </div>
            </div>
            {trialFields.map((field) => {
              return (
                <DynamicField
                  key={field.trialField.id}
                  trialField={field.trialField}
                  formik={formik}
                  disabled={!isRequiredFields}
                  createLogImage={createLogImage}
                />
              );
            })}
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={classNames(styles.button, 'p-button-secondary')}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default NewLogForm;
