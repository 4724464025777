export const tier = {
  essentials: 'b512649e-cbe1-4f35-a799-6435bd60e77d',
  collaborate: '8e59f3d6-51b8-443f-8988-7d2a5985e10c',
  enterprise: '44e02a4a-a8dd-4f4e-9444-b1e812069e0c',
};

export const titleText = {
  denyAdmin:
    "This functionality is not available for your plan. If you want change plan follow to 'Billing & Subscriptions' page.",
  denyUser:
    'This functionality is not available for your plan. If you want change plan, please contact your company administrator.',
};

export const dialogText = {
  limitAdmin: [
    `You have reached the limit of your subscription plan. If you want to change plan, please follow `,
    ` page to proceed `,
  ],
  denyAdmin: (status) => {
    return status === 'CANCELED'
      ? [
          `Your subscription status is "${status}".  It seems the payments were failed and now you won't be able to access your data.
     You can start new subscription at `,
          ` if you are planning to continue work with application.
      As always, our support team can be reached at `,
        ]
      : [
          `Your subscription status is "${status}".  It seems the payment was failed and we temporally froze your access to the application.
       Please visit the `,
          ` page and check yours payment method. We will try to charge you up to two hours.
        As always, our support team can be reached at `,
        ];
  },
  limitUser: [
    `You have reached the limit of your subscription plan. Please contact your company administrator to change plan.`,
  ],
  denyUser: [
    'Your company has no active subscriptions. Please contact your company administrator to create or renew a subscription.',
  ],
};

export const errorCodes = {
  NOT_PROVIDED: {
    code: 'NOT_PROVIDED',
    text: 'This field should be provided.',
  },
  MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  NOT_UNIQUE: 'This field should be unique.',
  REFERENCES_EXIST: {
    code: 'REFERENCES_EXIST',
    text: 'References is already exist',
  },
  CROP_TEMPLATE_REFERENCES_EXIST: {
    code: 'CROP_TEMPLATE_REFERENCES_EXIST',
    text: ' can not be removed because it is assigned to at least one trial.',
  },
  TASK_ALREADY_COMPLETED: {
    code: 'TASK_ALREADY_COMPLETED',
    text: 'Task is already Completed.',
  },
  BUCKET_NOT_EXIST: {
    code: 'BUCKET_NOT_EXIST',
    text: 'Destination bucket does not exist',
  },
  NOT_UNIQUE_BUCKET_NAME: {
    code: 'NOT_UNIQUE_BUCKET_NAME',
    text: 'Bucket name ia already existed',
  },
  NOT_VALID_IMAGE_TYPE: {
    code: 'NOT_VALID_IMAGE_TYPE',
    text: 'Invalid image type. Supported image types are PNG, JPG, JPEG',
  },
  INVALID_EMAIL_FORMAT: {
    code: 'Invalid format.',
    text: 'Invalid email format',
  },
  CONFLICT: {
    code: 'CONFLICT',
    text: 'This plant has been updated by another user. Please refresh the page and then save your updates.',
  },
  CANNOT_UPDATE_COMPLETED_TASK: {
    code: 'CANNOT_UPDATE_COMPLETED_TASK',
    text: 'Can not update completed task',
  },
  DEFAULT_MESSAGE: {
    code: 'DEFAULT_MESSAGE',
    text: 'Something went wrong.',
  },
};

export const queryTypes = {
  PRODUCT: 'product',
  TASK: 'task',
  TRIAL: 'trial',
  CROP: 'crop',
  LOCATION: 'location',
  ANALYTIC_COMPANY: 'analytic/company',
};

export const subscriptionStatus = {
  ACTIVE: 'ACTIVE',
  TRIALING: 'TRIALING',
  PAUSE: 'PAUSE',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  CANCELED: 'CANCELED',
  PAST_DUE: 'PAST_DUE',
  UNPAID: 'UNPAID',
};

export const links = {
  HOME: '/',
  ANALYTIC_CUSTOMER: '/analytic-customer',
  ANALYTIC_KPI: '/analytic-kpi',
  DASHBOARD: '/dashboard',
  LOCATIONS: '/locations',
  LOCATION: '/location',
  TASKS: '/tasks',
  PRICING: '/pricing',
  PLANTS: '/plants',
  TRIALS: '/trials',
  TRIAL: '/trial',
  PRODUCTS: '/products',
  PRODUCT: '/product',
  EMPLOYEES: '/employees',
  COMPANY_INFO: '/administration/company-info',
  USER_AGREEMENT: '/user-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  SIGNUP: '/signup',
  LABEL: '/settings/label',
  LABELS: '/settings/labels',
  SYSTEM: '/settings/system',
  REPLICATION: '/settings/replication',
  SETTINGS: '/settings',
  HISTORY: '/history',
  SUPPORT: '/support',
  CONTACT_US: '/contact-us',
  LOGIN: '/login',
};

export const userRoles = {
  COMPANY_BROWSE: 'company:browse',
  COMPANY_MANAGE: 'company:manage',
  PPT_BROWSE_COMPANY: 'ppt:browse:company',
  PPT_BROWSE_LOG: 'ppt:browse:log',
  PPT_BROWSE_TASK: 'ppt:browse:task',
  PPT_BROWSE_TRIAL: 'ppt:browse:trial',
  PPT_MANAGE_COMPANY: 'ppt:manage:company',
  PPT_MANAGE_LOG: 'ppt:manage:log',
  PPT_MANAGE_TASK: 'ppt:manage:task',
  PPT_MANAGE_TRIAL: 'ppt:manage:trial',
};

export const locales = {
  SUNDAY: 'en-US',
  MONDAY: 'en-GB',
};

export const dateFormat = {
  'dd/MM/yyyy': 'DD/MM/yyyy',
  'MM/dd/yyyy': 'MM/DD/yyyy',
  'yyyy/MM/dd': 'yyyy/MM/DD',
};

export const timeFormat = {
  'yyyy/MM/dd': 'HH:mm',
  'dd/MM/yyyy': 'HH:mm',
  'MM/dd/yyyy': 'h:mm A',
};

export const dateTimeFormat = {
  'yyyy/MM/dd': 'yyyy/MM/DD HH:mm',
  'dd/MM/yyyy': 'DD/MM/yyyy HH:mm',
  'MM/dd/yyyy': 'MM/DD/yyyy h:mm A',
};

export const datePickerTimeFormat = {
  'yyyy/MM/dd': 'HH:mm',
  'dd/MM/yyyy': 'HH:mm',
  'MM/dd/yyyy': 'h:mm a',
};

export const popUp = {
  severities: {
    SUCCESS: 'success',
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
  },
  summary: {
    SUCCESSFUL: 'Successful',
    ERROR: 'Error',
  },
};

export const expiredLinkMessages = {
  '?message=Access%20expired.&success=false': [
    ' Sorry, this link was expired',
    'Error',
    'error',
  ],
  '?message=This%20URL%20can%20be%20used%20only%20once&success=false': [
    'Sorry, this link can be used only once',
    'Error',
    'error',
  ],
  '?success=true&message=You%20can%20now%20login%20to%20the%20application%20with%20the%20new%20password.':
    [' You successfully set the password.', 'Successful', 'success'],
};

export const showDetailsFields = {
  DESCRIPTION: 'description',
  NOTE: 'note',
};

export const freshdesk = {
  hide: 'hide',
  show: 'show',
  open: 'open',
  close: 'close',
  destroy: 'destroy',
  boot: 'boot',
  identify: 'identify',
  launcher: 'launcher',
  ticketForm: 'ticketForm',
};

export const sortOrders = {
  1: 'ASC',
  '-1': 'DESC',
};

export const fieldTypes = {
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATE_TIME',
  CHECKBOX: 'CHECKBOX',
  NUMBER: 'NUMBER',
  INTEGER: 'INTEGER',
  IMAGE: 'IMAGE',
  NOTE: 'NOTE',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
};

export const fieldTypesOptions = [{ name: 'PROGRESS' }, { name: 'EVENT' }];
