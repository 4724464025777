import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';

import { setPopUp } from '../../../../../reduxStore/popUp/actions';
import { popUp, tier, titleText } from '../../../../Common/globalConstants';
import { urls, useRequest } from '../../../../Common/ApiServices';
import { wait } from '../../../../Common/utils';
import { getExportLogParams } from '../../../utils';
import { loadingStatus, whileTimers } from '../../../constants';
import { groupOptions } from '../constants';

import NewReportPDFForm from '../../../../ReportPDF/NewReportPDF/NewReportPDFForm';

import styles from './Header.module.scss';

const Header = ({
  groupedBy,
  setGroupedBy,
  editing,
  selectedColumns,
  columns,
  onColumnToggle,
  setExporting,
  logToEdit,
  sort,
  filters,
  pagination,
  entity,
}) => {
  const [displayReportModal, setDisplayReportModal] = useState(false);
  const permissions = useSelector((state) => state.permissions.permissions);
  const subscription = useSelector((state) => state.subscription.uuid);
  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const dispatch = useDispatch();
  const { sendRequest } = useRequest({});

  const isPlantDateSelected = () => {
    return Object.keys(filters).includes('plantDate');
  };

  const titleMessage = useMemo(() => {
    if (subscription !== tier.essentials) return '';
    return permissions.includes('ppt:manage:company')
      ? titleText.denyAdmin
      : titleText.denyUser;
  }, [permissions, subscription]);

  const onGroupByChanged = (event) => {
    setGroupedBy({ value: event.value, column: event.value.code });
  };

  const checkExportStatus = async (id) => {
    let exportStatus;
    let attempt = 0;
    while (attempt < whileTimers.maxAttempt) {
      const requestData = {
        url: urls.EXPORT_LOG_STATUS,
        method: 'POST',
        data: { id: id },
      };
      const response = await sendRequest(requestData);
      exportStatus = response.data;

      if (exportStatus.status === loadingStatus.READY) {
        attempt = whileTimers.maxAttempt;
        const link = document.createElement('a');
        link.href = exportStatus.url;
        document.body.appendChild(link);
        link.click();
        setExporting(false);
      } else if (exportStatus.status === loadingStatus.FAILED) {
        dispatch(
          setPopUp({
            severity: popUp.severities.ERROR,
            summary: popUp.summary.ERROR,
            detail: 'Export was failed. Please contact your administrator.',
            life: 5000,
          })
        );
        attempt = whileTimers.maxAttempt;
        setExporting(false);
      } else {
        attempt++;
        await wait(whileTimers.loopDelay);
      }
    }
  };

  const exportLogs = async () => {
    setExporting(true);
    const requestData = {
      url: urls.EXPORT_LOGS,
      method: 'POST',
      data: getExportLogParams(
        groupedBy,
        sort,
        filters,
        pagination,
        entity,
        logToEdit,
        true,
        selectedColumns
      ),
    };
    const response = await sendRequest(requestData);
    await checkExportStatus(response.data.id);
  };

  return (
    <div className={styles.header}>
      {displayReportModal && (
        <NewReportPDFForm
          displayReportModal={displayReportModal}
          setDisplayReportModal={setDisplayReportModal}
          filters={filters}
          selectedColumns={selectedColumns}
        />
      )}
      <div className="p-flex-row">
        <Dropdown
          value={groupedBy.value}
          className={classNames(styles.groupByDropdown, 'p-mr-1')}
          options={groupOptions}
          optionLabel="name"
          placeholder="Group By"
          disabled={editing}
          onChange={onGroupByChanged}
        />
        <MultiSelect
          className={styles.multiselect}
          value={selectedColumns}
          options={columns}
          optionLabel="header"
          disabled={editing}
          placeholder="Display Columns"
          onChange={onColumnToggle}
          panelClassName={styles.selectAll}
        />
      </div>
      <div className={classNames(styles.buttonsGroup)}>
        <span
          className="tooltip-wrapper"
          data-pr-tooltip="Plant Date filter should be selected to proceed!"
        >
          <Button
            label="Generate PDF"
            icon="pi pi-upload"
            title={titleMessage}
            className={classNames(
              'p-button-help p-button-raised',
              isTrialTracker && styles.hideElement
            )}
            disabled={
              editing ||
              subscription === tier.essentials ||
              !isPlantDateSelected()
            }
            onClick={() => setDisplayReportModal(true)}
          />
        </span>
        <Button
          label="Export"
          icon="pi pi-upload"
          title={titleMessage}
          className={classNames(
            'p-button-help p-button-raised',
            isTrialTracker && styles.hideElement
          )}
          disabled={editing || subscription === tier.essentials}
          onClick={exportLogs}
        />
      </div>
      {!isPlantDateSelected() && (
        <Tooltip target=".tooltip-wrapper" position="bottom" />
      )}
    </div>
  );
};

export default Header;
