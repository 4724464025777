import * as yup from 'yup';

export const initialPlantFormSchema = (plantInfo) => {
  return {
    name: plantInfo.name,
    code: plantInfo.code,
    active: plantInfo.active,
    program: plantInfo.program || null,
    genus: plantInfo.genus || null,
    origin: plantInfo.origin || '',
    variety: plantInfo.variety || '',
    species: plantInfo.species || '',
    stockRequired: plantInfo.stockRequired || 0.0,
    yieldEstimation: plantInfo.yieldEstimation || 0.0,
    series: plantInfo.series || null,
    breeder: plantInfo.breeder || null,
    marketStatus: plantInfo.marketStatus || null,
    hardinessZone: plantInfo.hardinessZone || null,
    patentNumber: plantInfo.patentNumber || '',
    tradeName: plantInfo.tradeName || '',
    scientificName: plantInfo.scientificName || '',
    color: plantInfo.color || null,
    description: plantInfo.description || '',
    note: plantInfo.note || '',
    imageId: plantInfo.image || '',
    pdfFileLink: plantInfo.pdfFileLink || null,
  };
};

const lengthError = 'Maximum length exceeded.';

export const plantFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Plant Name should be provided.')
    .max(50, lengthError),
  code: yup
    .string()
    .required('Plant Code should be provided.')
    .max(25, lengthError),
  description: yup.string().max(256, lengthError),
  patentNumber: yup.string().max(32, lengthError),
  tradeName: yup.string().max(50, lengthError),
  origin: yup.string().max(128, lengthError),
  variety: yup.string().max(100, lengthError),
  stockRequired: yup
    .number()
    .nullable()
    .max(999999999.9, 'Max value 999999999.9')
    .min(0.0, 'Min value 0.0'),
  yieldEstimation: yup
    .number()
    .nullable()
    .max(999999999.9, 'Max value 999999999.9')
    .min(0.0, 'Min value 0.0'),
  scientificName: yup.string().max(50, lengthError),
  note: yup.string().max(1024, lengthError),
});
