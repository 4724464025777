export const GET_TRIAL_FILTERS = 'GET_TRIAL_FILTERS';
export const TRIAL_FILTERS_REQUEST = 'TRIAL_FILTERS_REQUEST';
export const TRIAL_FILTERS_SUCCESS = 'TRIAL_FILTERS_SUCCESS';
export const TRIAL_FILTERS_FAIL = 'TRIAL_FILTERS_FAIL';

export const getTrialFilters = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
}) => ({
  type: GET_TRIAL_FILTERS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
  },
});

export const trialFiltersRequest = () => ({ type: TRIAL_FILTERS_REQUEST });

export const trialFiltersSuccess = (
  sections,
  programs,
  genuses,
  series,
  species,
  colors,
  breeders,
  marketStatuses,
  hardinessZones
) => ({
  type: TRIAL_FILTERS_SUCCESS,
  sections: sections,
  programs: programs,
  genuses: genuses,
  series: series,
  species: species,
  colors: colors,
  breeders: breeders,
  marketStatuses: marketStatuses,
  hardinessZones: hardinessZones,
});

export const trialFiltersFail = (error) => ({
  type: TRIAL_FILTERS_FAIL,
  error: error,
});
