export const GET_REPORT_OPTIONS = 'GET_REPORT_OPTIONS';
export const REPORT_OPTIONS_REQUEST = 'REPORT_OPTIONS_REQUEST';
export const REPORT_OPTIONS_SUCCESS = 'REPORT_OPTIONS_SUCCESS';
export const REPORT_OPTIONS_FAIL = 'REPORT_OPTIONS_FAIL';

export const getReportOptions = ({
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
  trialId,
}) => ({
  type: GET_REPORT_OPTIONS,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
    trialId: trialId,
  },
});

export const reportOptionsRequest = () => ({ type: REPORT_OPTIONS_REQUEST });

export const reportOptionsSuccess = (crops, fields) => ({
  type: REPORT_OPTIONS_SUCCESS,
  crops: crops,
  fields: fields,
});

export const reportOptionsFail = (error) => ({
  type: REPORT_OPTIONS_FAIL,
  error: error,
});
