import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import merge from 'deepmerge';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Paginator } from 'primereact/paginator';
import { ProgressBar } from 'primereact/progressbar';
import { getTrialFilters } from '../../../../reduxStore/trialFilters/actions';
import { isAuth } from '../../../../auth/auth-service';
import {
  getFormattedDate,
  getImportErrorMessage,
  wait,
} from '../../../Common/utils';
import ShowDetails from '../../../Common/showDetails/showDetails';
import {
  dateFormat,
  popUp,
  showDetailsFields,
} from '../../../Common/globalConstants';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import PlantForm from '../PlantForm/PlantForm';
import BatchForm from '../BatchForm/BatchForm';
import Preview from '../../../Common/Preview/Preview';
import { initialPlantParams } from '../constants';
import urls from '../../../Common/ApiServices/urls';
import { useRequest } from '../../../Common/ApiServices';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import styles from './PlantList.module.scss';

const PlantList = ({
  programs,
  genuses,
  series,
  species,
  colors,
  breeders,
  marketStatuses,
  hardinessZones,
  newFilters,
}) => {
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plantParams, setPlantParams] = useState(initialPlantParams);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [sort, setSort] = useState({ field: 'name', order: 1 });
  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [totalRecords, setTotalRecords] = useState(null);
  const [displayPlantModal, setDisplayPlantModal] = useState(false);
  const [displayBatchModal, setDisplayBatchModal] = useState(false);
  const [plantIsUpdated, setPlantIsUpdated] = useState(false);
  const [plantIsCreated, setPlantIsCreated] = useState(false);
  const [plantIsDeleted, setPlantIsDeleted] = useState(false);
  const [batchIsUpdated, setBatchIsUpdated] = useState(false);
  const [newPlantId, setNewPlantId] = useState(null);
  const [plantsUpload, setPlantsUpload] = useState({
    inProgress: false,
    finished: false,
  });
  const [plantsToDelete, setPlantsToDelete] = useState(null);
  const [deletePlantsDialog, setDeletePlantsDialog] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState(420);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const fileUploadRef = useRef(null);
  const tableRef = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const showElements = useMemo(
    () => (isTrialTracker ? 'none' : 'inline-flex'),
    [isTrialTracker]
  );

  const checkThumbnail = useCallback(
    async (plantId) => {
      let isReady = false;
      let attempt = 0;
      while (attempt < 10) {
        const requestData = {
          url: urls.CHECK_PLANTS_THUMBNAIL,
          method: 'POST',
          data: { plant: { id: { in: [plantId] } } },
        };
        const response = await sendRequest(requestData);

        if (response.data.plantIds?.length === 0) {
          isReady = true;
          break;
        } else {
          await wait(3000);
          attempt += 1;
        }
      }
      if (attempt === 10) {
        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: 'Failed to create a thumbnail.',
            life: 5000,
            sticky: null,
          })
        );
        isReady = true;
      }
      return isReady;
    },
    [dispatch, logout]
  );

  const fetchPlants = useCallback(async () => {
    setLoading(true);
    let thumbnailIsReady = true;
    if (plantIsUpdated || plantIsCreated) {
      const plantId = plantIsCreated ? newPlantId : selectedPlant.id;
      thumbnailIsReady = await checkThumbnail(plantId);
    }
    if (thumbnailIsReady) {
      const requestData = {
        url: urls.SEARCH_PLANTS,
        method: 'POST',
        data: plantParams,
      };
      const response = await sendRequest(requestData);
      if (response) {
        const plants = await response.data.results.map((plant) => ({
          id: plant.plant.id,
          versionNumber: plant.plant.version.number,
          name: plant.plant.name,
          active: plant.plant.active,
          code: plant.plant.code,
          origin: plant.plant.origin,
          stockRequired: plant.plant.stockRequired,
          yieldEstimation: plant.plant.yieldEstimation,
          variety: plant.plant.variety,
          createDate: getFormattedDate(
            plant.plant.createDate,
            dateFormat[plant.plant.dateFormat.datePattern]
          ),
          program: plant.program ? plant.program.name : null,
          genus: plant.genus ? plant.genus.name : null,
          series: plant.series ? plant.series.name : null,
          breeder: plant.breeder ? plant.breeder.name : null,
          marketStatus: plant.marketStatus ? plant.marketStatus.name : null,
          hardinessZone:
            plant.plant.hardinessZone && plant.plant.hardinessZone.zone,
          species: plant.plant.species && plant.plant.species.name,
          color: plant.plant.color ? plant.plant.color.name : null,
          patentNumber: plant.plant.patentNumber,
          tradeName: plant.plant.tradeName,
          scientificName: plant.plant.scientificName,
          description: plant.plant.description,
          image: plant.plant.image,
          pdfFile: plant.plant.pdfFile,
        }));
        setPlants(plants);
        setTotalRecords(response.data.page.total);
        setPlantIsDeleted(false);
        setPlantIsUpdated(false);
        setPlantIsCreated(false);
        setBatchIsUpdated(false);
      }
      setLoading(false);
      setPlantsUpload({ inProgress: false, finished: false });
    }
  }, [
    dispatch,
    logout,
    newPlantId,
    plantIsCreated,
    plantIsUpdated,
    plantParams,
    selectedPlant,
  ]);

  const updateFilters = useCallback((newFilters) => {
    if (!newFilters) {
      return null;
    } else {
      const newGenus = newFilters.genuses
        ? newFilters.genuses.map((class_) => class_.id)
        : null;
      const newPrograms = newFilters.programs
        ? newFilters.programs.map((program) => program.id)
        : null;
      const newSeries = newFilters.series
        ? newFilters.series.map((series) => series.id)
        : null;
      const newSpecies = newFilters.species
        ? newFilters.species.map((species) => species.id)
        : null;
      const newBreeders = newFilters.breeders
        ? newFilters.breeders.map((breeder) => breeder.id)
        : null;
      const newActive = newFilters.active ? newFilters.active.label : null;

      setPlantParams((oldParams) => {
        const { navigation } = oldParams;
        const newQuery = {
          ...(newGenus && { genus: { id: { in: newGenus } } }),
          ...(newPrograms && { program: { id: { in: newPrograms } } }),
          ...(newSeries && { series: { id: { in: newSeries } } }),
          ...(newSpecies && { species: { id: { in: newSpecies } } }),
          ...(newBreeders && { breeder: { id: { in: newBreeders } } }),
          ...(newActive && { plant: { active: newActive === 'Yes' } }),
        };
        return { navigation: navigation, query: newQuery };
      });
    }
  }, []);

  const onPlantSelect = useCallback((plant) => {
    setSelectedPlant(plant);
    setDisplayPlantModal(true);
  }, []);

  const nameBodyTemplate = useCallback(
    (rowData) => {
      return (
        <button
          className={styles.linkStyle}
          onClick={() => onPlantSelect(rowData)}
        >
          {rowData.name}
        </button>
      );
    },
    [onPlantSelect]
  );

  const onSort = useCallback(({ sortField, sortOrder }) => {
    setSort({ field: sortField, order: sortOrder });
  }, []);

  const onPageSelect = useCallback(({ first, rows }) => {
    setPagination({ first, rows });
  }, []);

  const sortFunc = useCallback(() => {
    return tableRef?.current?.props.value || plants;
  }, [plants]);

  const debouncedGlobalSearch = useRef(
    debounce((value) => setGlobalSearch(value), 500)
  ).current;

  const onGlobalSearch = useCallback(
    (event) => {
      debouncedGlobalSearch(event.target.value);
    },
    [debouncedGlobalSearch]
  );

  const onNewPlantCreate = useCallback(() => {
    setSelectedPlant(null);
    setDisplayPlantModal(true);
  }, []);

  const onBatchUpdate = useCallback(() => {
    setDisplayBatchModal(true);
  }, []);

  const actionData = useMemo(
    () => ({
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
    }),
    [dispatch, getAccessTokenSilently, logout]
  );

  const onPlantsUpload = useCallback(
    async (event) => {
      setPlantsUpload({ inProgress: true, finished: false });
      setShowCancelButton(false);
      const file = event.files[0];
      const contentType =
        file.name.split('.').pop() === 'csv' ? 'text/csv' : file.type;
      const requestData = {
        url: urls.IMPORT_PLANTS,
        method: 'POST',
        data: file,
        headers: [{ 'Content-Type': contentType }],
      };

      const response = await sendRequest(requestData);

      if (response) {
        setShowCancelButton(false);
        setPlantsUpload({ inProgress: false, finished: true });
        dispatch(getTrialFilters(actionData));
      }
    },
    [actionData, dispatch, getAccessTokenSilently, logout]
  );

  const header = (
    <div
      className={styles.tableHeader}
      style={{ justifyContent: showElements[2] }}
    >
      <div className={styles.tableLabel} style={{ display: showElements[0] }}>
        <Button
          className="p-button-raised"
          style={{ display: showElements }}
          label="New Plant"
          icon="pi pi-plus"
          onClick={() => onNewPlantCreate()}
        />
        <FileUpload
          className={classNames('p-mr-2 ', styles.tabFontSize)}
          style={{ display: showElements }}
          ref={fileUploadRef}
          mode="basic"
          accept=".csv,.xls,.xlsx"
          maxFileSize={1000000}
          label="Import"
          chooseLabel="Import Plants"
          customUpload
          uploadHandler={onPlantsUpload}
          onSelect={() => setShowCancelButton(true)}
        />
        {showCancelButton ? (
          <Button
            style={{ background: 'transparent', display: 'inline-flex' }}
            onClick={() => {
              fileUploadRef.current.clear();
              setShowCancelButton(false);
            }}
            className="p-button p-button-rounded p-button-danger p-button-outlined p-button-icon-only"
            icon="pi pi-times"
          />
        ) : null}
        <Button
          className="p-button-primary p-button-raised"
          style={{ display: showElements }}
          label="Update"
          disabled={!selectedPlants.length}
          onClick={onBatchUpdate}
        />
        <Button
          className="p-button-secondary p-button-raised"
          style={{ display: showElements }}
          label="Delete"
          disabled={!selectedPlants.length}
          onClick={() => confirmDeletePlants(selectedPlants)}
        />
      </div>
      <span className={classNames('p-input-icon-left', styles.searchField)}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => onGlobalSearch(e)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const footer = () => {
    if (!totalRecords) {
      let emptyMessage = '';
      if (totalRecords === 0) {
        emptyMessage = "You don't have plants yet. Please create a plant.";
      }
      return (
        <div className="generic-list-message">
          <h3 className="p-text-center">{emptyMessage}</h3>
        </div>
      );
    } else {
      return (
        <Paginator
          rows={pagination.rows}
          totalRecords={totalRecords}
          first={pagination.first}
          className="tabPaginator"
          rowsPerPageOptions={[20, 50, 100]}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          onPageChange={onPageSelect}
        />
      );
    }
  };

  const confirmDeletePlants = useCallback((plants) => {
    setPlantsToDelete(plants);
    setDeletePlantsDialog(true);
  }, []);

  const removeBodyTemplate = useCallback(
    (rowData) => {
      return isTrialTracker ? (
        ''
      ) : (
        <React.Fragment>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeletePlants([rowData])}
          />
        </React.Fragment>
      );
    },
    [confirmDeletePlants, isTrialTracker]
  );

  const pdfBodyTemplate = useCallback((rowData) => {
    return (
      <a
        href={rowData.pdfFile?.url || ''}
        target="_blank"
        rel="noreferrer"
        className={styles.pdfIcon}
      >
        {rowData.pdfFile?.name ? <i className="pi pi-file-pdf" /> : ''}
      </a>
    );
  }, []);

  const hideDeletePlantDialog = useCallback(() => {
    setSelectedPlants([]);
    setDeletePlantsDialog(false);
  }, []);

  const deletePlants = useCallback(async () => {
    setDeletePlantsDialog(false);
    const plantsToDeleteIds = plantsToDelete.map((plant) => plant.id);

    const requestData = {
      url: urls.DELETE_PLANTS,
      method: 'POST',
      data: {
        id: {
          in: plantsToDeleteIds,
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Selected plant(s) were deleted.',
          life: 5000,
        })
      );
      setPlantIsDeleted(true);
    }
    setSelectedPlants([]);
  }, [dispatch, plantsToDelete]);

  const changeFrozenWidth = useCallback(
    (event) => {
      if (
        event.column.columnKey === 'name' ||
        event.column.columnKey === 'image'
      ) {
        setFrozenWidth(frozenWidth + event.delta);
      }
    },
    [frozenWidth]
  );

  const deletePlantsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeletePlantDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-raised p-button-danger"
        onClick={deletePlants}
      />
    </React.Fragment>
  );

  const imageBodyTemplate = useCallback((rowData) => {
    return (
      <img
        src={rowData.image?.thumbnail}
        onError={(e) => {
          e.target.src = '';
        }}
        className={styles.plantThumb}
        onClick={() => setPreviewUrl(rowData?.image?.full)}
        alt={''}
      />
    );
  }, []);

  const breadCrumbItems = [{ label: 'Plants' }];

  const statusBodyTemplate = (rowData) => {
    return (
      <div className={styles.circleCentered}>
        <i
          className={classNames(
            styles.statusIcon,
            rowData.active ? 'pi pi-circle-on' : 'pi pi-circle-off'
          )}
        />
      </div>
    );
  };

  useEffect(() => {
    updateFilters(newFilters);
  }, [newFilters, updateFilters]);

  useEffect(() => {
    if (sort) {
      setPlantParams((oldParams) => {
        let params = cloneDeep(oldParams);
        params.navigation.sort[0].key = sort.field;
        params.navigation.sort[0].order = sort.order === 1 ? 'ASC' : 'DESC';
        return params;
      });
    }
  }, [sort]);

  useEffect(() => {
    setPlantParams((oldParams) => {
      let params = cloneDeep(oldParams);
      params.navigation.page.from = pagination.first;
      params.navigation.page.size = pagination.rows;
      return params;
    });
  }, [pagination]);

  useEffect(() => {
    setPlantParams((oldParams) => {
      let params = cloneDeep(oldParams);
      if (globalSearch) {
        const searchParams = { query: { search: { is: globalSearch } } };
        params = merge(params, searchParams);
      } else if (!globalSearch && params.query.search) {
        delete params.query.search;
      }
      return params;
    });
  }, [globalSearch]);

  useEffect(() => {
    !loading && fetchPlants();
  }, [plantParams]);

  useEffect(() => {
    if (plantIsDeleted) {
      fetchPlants();
    } else if (plantIsCreated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'New plant was created.',
          life: 5000,
          sticky: null,
        })
      );
      fetchPlants();
    } else if (plantIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Plant was updated.',
          life: 5000,
          sticky: null,
        })
      );
      fetchPlants();
      setSelectedPlant(null);
    } else if (batchIsUpdated) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Batch of plants was updated.',
          life: 5000,
          sticky: null,
        })
      );
      setSelectedPlants([]);
      fetchPlants();
    } else if (plantsUpload.finished) {
      setSort({ field: 'createDate', order: -1 });
    }
  }, [
    plantIsDeleted,
    plantIsUpdated,
    batchIsUpdated,
    plantIsCreated,
    plantsUpload.finished,
  ]);

  useEffect(() => {
    if (error) {
      setPlantsUpload({ inProgress: false, finished: false });
      if (error.response?.data?.errors) {
        dispatch(
          setPopUp({
            severity: popUp.severities.ERROR,
            summary: popUp.summary.ERROR,
            detail: '',
            life: 5000,
            sticky: true,
            content: (
              <div className="importErrorsTable" style={{ flex: '1' }}>
                <div className="importInfoBlock">
                  <i
                    className="pi pi-exclamation-triangle"
                    style={{ fontSize: '3rem' }}
                  />
                  <div className="importInfoMessage">
                    Check the following errors
                  </div>
                </div>
                {getImportErrorMessage(error.response.data.errors)}
              </div>
            ),
          })
        );
      }
    }
  }, [error]);

  if (plantsUpload.inProgress) {
    return (
      <div className="generic-list-message import">
        <h2 className="p-text-center">
          Please wait while the plants are imported...
        </h2>
        <ProgressBar
          mode="indeterminate"
          style={{ height: '7px', width: '25%', margin: '0 auto' }}
          color="#0097A7"
        />
      </div>
    );
  } else {
    return (
      <div className={`${styles.plantList} list-generic`}>
        {previewUrl && <Preview url={previewUrl} hidePreview={setPreviewUrl} />}
        <PlantForm
          plantId={selectedPlant?.id || null}
          programs={programs}
          genuses={genuses}
          series={series}
          species={species}
          colors={colors}
          breeders={breeders}
          marketStatuses={marketStatuses}
          hardinessZones={hardinessZones}
          displayPlantModal={displayPlantModal}
          setDisplayPlantModal={setDisplayPlantModal}
          setSelectedPlant={setSelectedPlant}
          setPlantIsUpdated={setPlantIsUpdated}
          setPlantIsCreated={setPlantIsCreated}
          plantIsUpdated={plantIsUpdated}
          plantIsCreated={plantIsCreated}
          setNewPlantId={setNewPlantId}
        />

        <BatchForm
          displayBatchModal={displayBatchModal}
          setDisplayBatchModal={setDisplayBatchModal}
          selectedPlants={selectedPlants}
          setSelectedPlants={setSelectedPlants}
          programs={programs}
          genuses={genuses}
          series={series}
          colors={colors}
          species={species}
          breeders={breeders}
          marketStatuses={marketStatuses}
          hardinessZones={hardinessZones}
          setBatchIsUpdated={setBatchIsUpdated}
        />
        <BreadCrumb items={breadCrumbItems} />
        <DataTable
          ref={tableRef}
          className={classNames(
            'table-generic p-datatable-sm',
            styles.customTableStyle
          )}
          value={plants}
          header={header}
          reorderableColumns
          resizableColumns
          columnResizeMode="expand"
          selection={selectedPlants}
          onSelectionChange={(e) => setSelectedPlants(e.value)}
          selectionMode="checkbox"
          dataKey="id"
          sortField={sort.field}
          sortOrder={sort.order}
          onSort={onSort}
          loading={loading}
          scrollable
          emptyMessage=""
          frozenWidth={frozenWidth + 'px'}
          onColumnResizeEnd={(event) => changeFrozenWidth(event)}
        >
          <Column
            field="active"
            reorderable
            columnKey="active"
            header="Active"
            body={statusBodyTemplate}
            headerStyle={{ width: '50px', height: '48px' }}
            bodyStyle={{ height: '50px' }}
            frozen
            sortFunction={sortFunc}
          />
          <Column
            selectionMode="multiple"
            headerStyle={{
              visibility: showElements[0],
              width: '40px',
              height: '48px',
              padding: '0 7px',
            }}
            bodyStyle={{ visibility: showElements[0], width: '40px' }}
            frozen
          />
          <Column
            header="Image"
            body={imageBodyTemplate}
            headerStyle={{ width: '60px' }}
            bodyStyle={{ height: '63px' }}
            columnKey="image"
            frozen
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="name"
            sortField="name"
            columnKey="name"
            header="Name"
            body={nameBodyTemplate}
            sortFunction={sortFunc}
            headerStyle={{ width: '270px' }}
            frozen
            sortable
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="code"
            columnKey="code"
            header="Code"
            headerStyle={{ width: '150px', height: '48px' }}
            bodyStyle={{ height: '63px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            field="createDate"
            columnKey="createDate"
            header="Create Date"
            headerStyle={{ width: '120px', height: '48px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="breeder"
            sortField="breederName"
            reorderable
            columnKey="breeder"
            header="Breeder"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="program"
            sortField="programName"
            columnKey="program"
            header="Program"
            headerStyle={{ width: '100px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="genus"
            sortField="className"
            columnKey="genus"
            header="Genus"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="origin"
            sortField="origin"
            columnKey="origin"
            header="Origin"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="variety"
            sortField="variety"
            columnKey="variety"
            header="Variety"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="stockRequired"
            sortField="stockRequired"
            columnKey="stockRequired"
            header="Stock Required"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="yieldEstimation"
            sortField="yieldEstimation"
            columnKey="yieldEstimation"
            header="Yield Estimation"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            field="series"
            sortField="seriesName"
            columnKey="series"
            header="Series"
            headerStyle={{ width: '120px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            field="tradeName"
            columnKey="tradeName"
            header="Trade Name"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="scientificName"
            columnKey="scientificName"
            header="Scientific Name"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="color"
            reorderable
            columnKey="color"
            header="Color"
            sortable
            sortFunction={sortFunc}
            headerStyle={{ width: '150px' }}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="species"
            sortField="species"
            columnKey="species"
            header="Species"
            headerStyle={{ width: '150px' }}
            sortable
            sortFunction={sortFunc}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="marketStatus"
            columnKey="marketStatus"
            header="Market Status"
            sortable
            sortFunction={sortFunc}
            headerStyle={{ width: '145px' }}
          />
          <Column
            field="patentNumber"
            columnKey="patentNumber"
            header="Patent Number"
            sortable
            sortFunction={sortFunc}
            headerStyle={{ width: '145px' }}
          />
          <Column
            className="p-text-nowrap p-text-truncate"
            field="hardinessZone"
            reorderable
            columnKey="hardinessZone"
            header="Min Hardiness Zone"
            sortable
            sortFunction={sortFunc}
            headerStyle={{ width: '170px' }}
          />
          <Column
            className="p-dt-tooltip p-text-nowrap p-text-truncate"
            field="description"
            sortField="description"
            columnKey="description"
            header="Description"
            headerStyle={{ width: '200px' }}
            body={(rowData) =>
              ShowDetails(rowData[showDetailsFields.DESCRIPTION])
            }
          />
          <Column
            field="pdfFile"
            header="PDF File"
            headerStyle={{ width: '200px' }}
            body={pdfBodyTemplate}
          />
          <Column
            field="remove"
            header="Remove"
            headerStyle={{ width: '70px' }}
            body={removeBodyTemplate}
          />
        </DataTable>
        {footer()}
        <Dialog
          visible={deletePlantsDialog}
          className="confirmDialog"
          header="Delete Confirmation"
          footer={deletePlantsDialogFooter}
          onHide={hideDeletePlantDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-info-circle p-mr-3"
              style={{ fontSize: '2rem' }}
            />
            {plantsToDelete && (
              <span>
                Are you sure you want to delete{' '}
                <b>{`${
                  plantsToDelete.length > 1
                    ? 'all selected plants'
                    : plantsToDelete[0].name
                }`}</b>
                ?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
};

export default PlantList;
