import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import classNames from 'classnames';

import styles from './LogoUploader.module.scss';

const LogoUploader = ({
  defaultImage,
  selectedImage,
  setSelectedImage,
  setCreatedImage,
}) => {
  const [backGroundImage, setBackGroundImage] = useState(defaultImage);

  const filePicker = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(file);
        setBackGroundImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearLogo = () => {
    setSelectedImage(null);
    setBackGroundImage(defaultImage);
    setCreatedImage(null);
  };

  const handlePick = () => {
    filePicker.current.click();
  };

  useEffect(() => {
    setBackGroundImage(selectedImage ? selectedImage : defaultImage);
  }, [selectedImage]);

  return (
    <div className={styles.logoUploader}>
      <div className={styles.logoButton} onClick={handlePick}>
        <img src={backGroundImage} />
      </div>
      <Button
        type="button"
        icon="pi pi-times"
        className={classNames(
          styles.dangerButton,
          'p-button-outlined p-button-rounded p-ml-auto'
        )}
        onClick={clearLogo}
      />
      <input
        className={styles.hidden}
        ref={filePicker}
        type="file"
        onChange={handleChange}
        accept="image/*"
      />
    </div>
  );
};

export default LogoUploader;
