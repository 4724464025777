import * as yup from 'yup';

export const initialReportFormSchema = () => {
  return {
    images: true,
    logsData: true,
    productApplication: true,
  };
};

export const reportFormSchema = yup.object().shape({
  images: yup.bool(),
  logsData: yup.bool(),
  productApplication: yup.bool(),
});
