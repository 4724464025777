import * as yup from 'yup';

export const initialTrialFormSchema = (locations) => {
  return {
    name: '',
    location: locations
      ? { id: locations[0].location.id, name: locations[0].location.name }
      : '',
    trialManager: '',
    startDate: null,
    closeDate: null,
    description: '',
    contract: false,
    logFrequency: null,
    products: [],
    cropTemplate: null,
    note: '',
    status: null,
  };
};

export const trialFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Trial Name should be provided.')
    .max(50, 'Maximum length exceeded.'),
  location: yup.object().required('Location should be provided.'),
  trialManager: yup.object().required('Trial Manager should be provided.'),
  startDate: yup.date().nullable(),
  closeDate: yup
    .date()
    .nullable()
    .min(yup.ref('startDate'), 'Close Date should be after Start Date.'),
  description: yup.string().max(256, 'Maximum length exceeded.'),
  contract: yup.bool(),
  logFrequency: yup.object().nullable(),
  status: yup.object().nullable(),
  cropTemplate: yup.object().nullable(),
  note: yup.string().max(256, 'Maximum length exceeded.'),
});
