import React, { forwardRef, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import DatePicker from 'datepicker-special-week-numbers';
import classNames from 'classnames';

import { MultiSelect } from 'primereact/multiselect';

import styles from './DynamicField.module.scss';

const DynamicField = ({ trialField, formik, disabled, createLogImage }) => {
  const [newImages, setNewImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const uploadRef = useRef(null);

  const onTemplateRemove = (file, callback) => {
    setNewImages((prevState) =>
      prevState.filter((file_) => file_.name !== file.name)
    );
    callback();
  };

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    const progressBar = uploading && (
      <div
        className="p-d-flex p-jc-end"
        style={{ position: 'absolute', right: '35px' }}
      >
        <h4>Uploading image(s)...</h4>
        <ProgressBar
          className="p-ml-5 p-as-center"
          mode="indeterminate"
          style={{ width: '250px', height: '7px' }}
        />
      </div>
    );
    return (
      <div
        className={`${className} p-jc-between`}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {progressBar}
      </div>
    );
  };

  const chooseOptions =
    newImages.length === 0
      ? {
          icon: 'pi pi-fw pi-plus',
          iconOnly: true,
          className:
            'p-button-rounded p-button-success p-button-outlined hidden',
        }
      : {
          icon: 'pi pi-fw pi-images',
          iconOnly: true,
          className: 'custom-choose-btn p-button-rounded p-button-outlined',
        };

  const imageTemplate = (file, props) => {
    return (
      <div
        className="p-d-flex p-ai-center p-flex-wrap"
        style={{ position: 'relative' }}
      >
        <div className="p-d-flex p-ai-center" style={{ width: '75%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={180}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-1 p-p-3"
          style={{
            fontSize: '3em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span
          style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }}
          className="p-my-2"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const CustomCalendarInput = forwardRef(
    ({ icon, icon2 = null, value, onClick, error, touch }, ref) => (
      <Button
        label={value}
        className={classNames(
          error && formik.touched[touch]
            ? styles.calendarButtonError
            : styles.calendarButton,
          'p-button-text p-button-plain'
        )}
        onClick={onClick}
        onBlur={() => {
          formik.handleBlur({ target: { name: touch } });
        }}
        ref={ref}
        disabled={isTrialTracker}
        type="button"
      >
        <i className={`pi ${icon}`} />
        {icon2 && <i className={`pi ${icon2}`} style={{ marginLeft: '5px' }} />}
      </Button>
    )
  );

  const renderInputField = (fieldType) => {
    switch (fieldType) {
      case 'TEXT':
        return (
          <InputText
            id={trialField.name}
            type="text"
            className={classNames(
              formik.errors[trialField.name] && 'p-invalid'
            )}
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        );
      case 'NOTE':
        return (
          <InputTextarea
            id={trialField.name}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            type="text"
            rows="4"
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        );
      case 'INTEGER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onValueChange={(e) =>
              formik.setFieldValue(trialField.name, e.value)
            }
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        );
      case 'NUMBER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            minFractionDigits={2}
            maxFractionDigits={5}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onValueChange={(e) =>
              formik.setFieldValue(trialField.name, e.value)
            }
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        );
      case 'DATE':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            weekLabel={'Wk'}
            className={styles.datePicker}
            showWeekNumbers
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'TIME':
        return (
          <DatePicker
            id={trialField.name}
            className={classNames(
              styles.calendarButton,
              isTrialTracker && styles.disabledStyle
            )}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik?.values[trialField.name]}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            dateFormat="h:mm a"
            timeFormat="h:mm a"
            disabled={disabled}
            customInput={
              <CustomCalendarInput
                icon="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'DATE_TIME':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            disabled={disabled}
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                icon2="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'CHECKBOX':
        return (
          <Checkbox
            id={trialField.name}
            onChange={formik.handleChange}
            value={formik.values[trialField.name]}
            checked={formik.values[trialField.name]}
            disabled={disabled}
          />
        );
      case 'SELECT': {
        return trialField.multiselect ? (
          <MultiSelect
            id={trialField.name}
            optionLabel="name"
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            options={trialField.values || []}
            onChange={formik.handleChange}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        ) : (
          <Dropdown
            id={trialField.name}
            optionLabel="name"
            options={trialField.values || []}
            onChange={formik.handleChange}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            disabled={disabled}
            value={formik.values[trialField.name]}
          />
        );
      }
      case 'IMAGE':
        return (
          <FileUpload
            ref={uploadRef}
            name={trialField.name}
            accept=".jpeg,.jpg,.png"
            maxFileSize={1000000}
            multiple={trialField.imageCount > 1}
            onSelect={(e) => {
              createLogImage(e.files[0], trialField.name);
            }}
            onRemove={() => formik.setFieldValue(trialField.name, null)}
            headerTemplate={headerTemplate}
            itemTemplate={imageTemplate}
            emptyTemplate={emptyTemplate}
            disabled={disabled}
            chooseOptions={chooseOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {
        <div
          className={classNames(
            'p-field p-grid p-ai-start',
            styles.dynamicField
          )}
        >
          <label htmlFor="plant" className="p-col-12 p-md-3 p-text-bold">
            {trialField.required ? `${trialField.label}*` : trialField.label}
          </label>
          <div className={classNames('p-col-12 p-md-9', styles.inputZone)}>
            {renderInputField(trialField.type)}
            {formik.touched[trialField.name] && formik.errors[trialField.name] && (
              <small
                id={`${trialField.name}-invalid`}
                className="p-error p-d-block"
              >
                {formik.errors[trialField.name]}
              </small>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default DynamicField;
