import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getCropOptions } from '../../../reduxStore/crop/actions';
import { getTrialFilters } from '../../../reduxStore/trialFilters/actions';
import { getProductFilters } from '../../../reduxStore/productFilters/actions';
import { getProfile } from '../../../reduxStore/trial/actions';
import { getSectionOptions } from '../../../reduxStore/section/actions';
import { getProfileFieldGroups } from '../../../reduxStore/profileFieldGroups/actions';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { valueNameSchema } from './ReferenceTableValidation';
import {
  getAuthConfig,
  checkBlocked,
  isAuth,
} from '../../../auth/auth-service';
import { checkSubscription } from '../utils';
import { urls } from '../../Common/ApiServices';
import styles from './ReferenceTable.module.scss';

const ReferenceTable = (props) => {
  const [displayValueModal, setDisplayValueModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referenceValues, setReferenceValues] = useState(null);
  const [maxLengthExceeded, setMaxLengthExceeded] = useState(false);

  const toast = useRef(null);
  const topToast = useRef(null);

  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const nameMapping = {
    breeders: 'breeders',
    programs: 'programs',
    genus: 'genus',
    series: 'series',
    colors: 'colors',
    'market-statuses': 'market statuses',
    zones: 'zones',
    logfrequencies: 'log frequencies',
    startforms: 'start forms',
    sectiontypes: 'section types',
    [urls.PRODUCT_CATEGORIES]: 'categories',
    [urls.PRODUCT_MANUFACTURERS]: 'manufacturers',
    [urls.PRODUCT_CLASSIFICATIONS]: 'classifications',
    [urls.PRODUCT_MIXERS]: 'mixers',
    [urls.PRODUCT_APPLICATION_METHODS]: 'application methods',
    [urls.PROFILE_FIELD_GROUPS]: 'profile field groups',
    [urls.POT_SIZES]: 'pot sizes',
  };

  const cascadeNames = {
    programs: 'program',
    genus: 'genus',
    series: 'series',
    colors: 'color',
    'market-statuses': 'market status',
  };

  const bindTo = {
    logfrequencies: 'trial',
    'start forms': 'crop',
    startforms: 'crop',
    breeders: 'plant',
    programs: 'program',
    genus: 'genus',
    series: 'series',
    'market-statuses': 'market status',
    zones: 'section',
    sectiontypes: 'section',
    [urls.PRODUCT_CATEGORIES]: 'product',
    [urls.PRODUCT_MANUFACTURERS]: 'product',
    [urls.PRODUCT_CLASSIFICATIONS]: 'product',
    [urls.PRODUCT_MIXERS]: 'product',
    [urls.PRODUCT_APPLICATION_METHODS]: 'product',
    [urls.PROFILE_FIELD_GROUPS]: 'product',
    [urls.POT_SIZES]: 'pot size',
  };

  const maxInputLength = 32;
  const inputStyle = maxLengthExceeded ? 'p-invalid p-d-block' : '';
  const spanStyle = maxLengthExceeded ? 'p-error p-d-block' : 'p-d-none';

  const onNewValueAdd = () => {
    setDisplayValueModal(true);
  };

  const onRowEditInit = (row) => {
    if (props.referenceBook) {
      setEditedValue(row.data);
      setReferenceValues(JSON.parse(JSON.stringify(props.values)));
    }
    setEditing(true);
  };

  const onRowEditSave = (event) => {
    checkForDuplicates(event.data.name, event.data.id).then((isDuplicate) => {
      if (!isDuplicate) {
        if (props.referenceBook) {
          refreshRefValues();
        } else if (props.setValues) {
          props.setValuesChanged(true);
          props.setInitialValues(JSON.parse(JSON.stringify(props.values)));
        }
        setEditing(false);
        setEditedValue(null);
      } else {
        onRowEditCancel();
      }
    });
    setMaxLengthExceeded(false);
  };

  const onRowEditCancel = () => {
    if (props.setValues) {
      const initialValues = JSON.parse(JSON.stringify(props.initialValues));
      props.setValues(initialValues);
      props.setValuesChanged(true);
    }
    setEditing(false);
    setEditedValue(null);
  };

  const onEditorValueChange = (fieldKey, props_, value) => {
    let updatedValues = [...props_.value];
    updatedValues[props_.rowIndex][props_.field] = value;
    if (value.length >= maxInputLength) {
      setMaxLengthExceeded(true);
    } else if (value.length < maxInputLength) {
      setMaxLengthExceeded(false);
    }

    if (props.referenceBook) {
      setReferenceValues(updatedValues);
      setEditedValue(props_.rowData);
    } else if (props.setValues) {
      props.setValues(updatedValues);
    }
  };

  const deleteValue = async (value) => {
    if (props.referenceBook) {
      try {
        const config = await getAuthConfig(
          true,
          logout,
          getAccessTokenSilently
        );
        await axios.delete(
          `${config.apiUrl}/${props.referenceName}/${value.id}`,
          config
        );
        refreshRefValues();
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: `${value.name} value was deleted from ${
            nameMapping[props.referenceName]
          }.`,
          life: 5000,
        });
      } catch (error) {
        checkBlocked(error, logout);
        checkSubscription(error, dispatch);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `${
            value.name
          } can not be removed because it is assigned to at least one ${
            bindTo[props.referenceName] && bindTo[props.referenceName]
          }.`,
          life: 5000,
        });
        console.log('error -> ', error);
      }
    } else if (props.setValues) {
      const initialValues = JSON.parse(JSON.stringify(props.initialValues));
      const valueExists = initialValues.some(
        (val) => val.id === value.id && val.name === value.name
      );
      if (props.fieldId && valueExists && props.initialType === 'SELECT') {
        try {
          const config = await getAuthConfig(
            true,
            logout,
            getAccessTokenSilently
          );
          const response = await axios.post(
            `${config.apiUrl}/validate-value-usage`,
            {
              profileFieldId: props.fieldId,
              profileFieldValue: value.name,
            },
            config
          );
          if (response.data.used) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: 'This value cannot be deleted because log records exist.',
              life: 5000,
            });
          } else {
            updateValues(value.name);
          }
        } catch (error) {
          checkBlocked(error, logout);
          checkSubscription(error, dispatch);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong.',
            life: 5000,
          });
          console.log('error -> ', error);
        }
      } else {
        updateValues(value.name);
      }
    }
  };

  const confirmDeleteValue = (value) => {
    if (Object.keys(cascadeNames).includes(props.referenceName)) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `Are you sure you want to delete this ${
          cascadeNames[props.referenceName]
        } and remove it from all assigned plants?`,
        accept: () => deleteValue(value),
      });
    } else {
      deleteValue(value);
    }
  };

  const updateValues = (name) => {
    const newValues = props.values.filter((val) => val.name !== name);
    props.setValues(newValues);
    props.setInitialValues(JSON.parse(JSON.stringify(newValues)));
    props.setValuesChanged(true);
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setDisplayValueModal(false);
    formik.resetForm();
  };

  const inputTextEditor = (fieldKey, props, field) => {
    return (
      <>
        <InputText
          type="text"
          value={props.rowData[field]}
          style={{ width: '100%' }}
          className={inputStyle}
          maxLength={maxInputLength}
          onChange={(e) => onEditorValueChange(fieldKey, props, e.target.value)}
        />
        <small id="username2-help" className={spanStyle}>
          Maximum length exceed.
        </small>
      </>
    );
  };

  const header = props.editable && (
    <div className={styles.tableHeader}>
      <div className={styles.tableLabel}>
        <Button
          className="p-button-raised"
          type="button"
          label="New Value"
          icon="pi pi-plus"
          disabled={editing}
          onClick={() => onNewValueAdd()}
        />
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          type="button"
          className="p-button-rounded p-button-warning"
          style={{ visibility: editing ? 'hidden' : 'visible' }}
          onClick={() => confirmDeleteValue(rowData)}
        />
      </React.Fragment>
    );
  };

  const formik = useFormik({
    initialValues: { name: '' },
    enableReinitialize: true,
    validationSchema: valueNameSchema,
    onSubmit: (values) => {
      checkForDuplicates(values.name, values.id).then((isDuplicate) => {
        if (!isDuplicate) {
          if (props.referenceBook) {
            refreshRefValues();
          } else if (props.setValues) {
            const newValues = [{ name: values.name }, ...props.values];
            props.setValues(newValues);
            props.setInitialValues(JSON.parse(JSON.stringify(newValues)));
            props.setValuesChanged(true);
          }
          onHide();
        }
      });
    },
  });

  const checkForDuplicates = async (name, id) => {
    let isDuplicate = false;
    let message = '';
    if (props.referenceBook) {
      let isSuccess = false;
      setLoading(true);
      if (editing) {
        isSuccess = await editValue(name, id);
      } else {
        isSuccess = await createNewValue(name);
      }
      isDuplicate = !isSuccess;
      setLoading(false);
    } else {
      isDuplicate = props.initialValues.some((val) =>
        id ? val.name === name && val.id !== id : val.name === name
      );
    }
    if (isDuplicate) {
      const currentToast = topToast.current || toast.current;
      currentToast.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Value with this name already exists.',
        life: 5000,
      });
    } else {
      if (editing) {
        message = `Value name was changed to ${name}.`;
      } else {
        message = `New ${name} value was added to ${
          nameMapping[props.referenceName]
        }.`;
      }
      toast.current.show({
        severity: 'success',
        summary: 'Successful',
        detail: message,
        life: 5000,
      });
    }

    return isDuplicate;
  };

  const createNewValue = async (name) => {
    try {
      const config = await getAuthConfig(true, logout, getAccessTokenSilently);
      await axios.post(
        `${config.apiUrl}/${props.referenceName}`,
        {
          name: name,
        },
        config
      );
      return true;
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
      return false;
    }
  };

  const editValue = async (name, id) => {
    try {
      const config = await getAuthConfig(logout, getAccessTokenSilently);
      await axios.put(
        `${config.apiUrl}/${props.referenceName}/${id}`,
        {
          name: name,
        },
        config
      );
      return true;
    } catch (error) {
      checkBlocked(error, logout);
      checkSubscription(error, dispatch);
      console.log('error -> ', error);
      return false;
    }
  };

  const onNameFilter = (value, filter) => {
    if (value && filter) {
      return (
        value.toUpperCase().indexOf(filter.toUpperCase()) >= 0 ||
        value.indexOf(editedValue?.name) >= 0
      );
    }
  };

  const actionData = {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuth(),
    getAccessTokenSilently: getAccessTokenSilently,
  };

  //ToDo Should be uncommented and added other reference names during refactoring whole component;

  // const getNewRefValues = {
  //   [urls.PROFILE_FIELD_GROUPS]: () => props.getProfileFieldGroups(actionData),
  //   [urls.PRODUCT_CATEGORIES]: () => props.getProductFilters(actionData),
  //   [urls.PRODUCT_CLASSIFICATIONS]: () => props.getProductFilters(actionData),
  //   [urls.PRODUCT_MANUFACTURERS]: () => props.getProductFilters(actionData),
  //   [urls.PRODUCT_MIXERS]: () => props.getProductFilters(actionData),
  //   [urls.PRODUCT_APPLICATION_METHODS]: () =>
  //     props.getProductFilters(actionData),
  // };

  const refreshRefValues = () => {
    if (props.referenceName === 'logfrequencies') {
      props.getProfile(actionData);
    } else if (
      props.referenceName === urls.START_FORMS ||
      props.referenceName === urls.POT_SIZES
    ) {
      props.getCropOptions({ ...actionData, id: props.trialId });
    } else if (['sectiontypes', 'zones'].includes(props.referenceName)) {
      props.getSectionOptions(actionData);
    } else if (
      props.referenceName === urls.PRODUCT_CATEGORIES ||
      props.referenceName === urls.PRODUCT_CLASSIFICATIONS ||
      props.referenceName === urls.PRODUCT_MANUFACTURERS ||
      props.referenceName === urls.PRODUCT_MIXERS ||
      props.referenceName === urls.PRODUCT_APPLICATION_METHODS
    ) {
      props.getProductFilters(actionData);
    } else if (props.referenceName === urls.PROFILE_FIELD_GROUPS) {
      props.getProfileFieldGroups(actionData);
    } else {
      props.getTrialFilters(actionData);
    }
  };

  //ToDo Should be uncommented during refactoring whole component;

  // useEffect(() => {
  //   props.referenceName && getNewRefValues[props.referenceName]();
  // }, [props.referenceName]);

  return (
    <div
      className={styles.referenceTable}
      style={{ marginBottom: props.margin }}
    >
      <Toast ref={toast} />
      <Dialog
        className={styles.formDataDialog}
        visible={displayValueModal}
        onHide={() => confirm(false)}
      >
        <Toast ref={topToast} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={formik.errors.name ? 'p-invalid' : null}
                />
                {formik.touched.name && formik.errors.name && (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(!formik.values.name || formik.errors.name)}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(false)}
            />
          </div>
        </form>
      </Dialog>
      <DataTable
        className="p-datatable-sm"
        loading={loading}
        value={props.referenceBook && editing ? referenceValues : props.values}
        header={header}
        scrollable
        editMode="row"
        onRowEditCancel={onRowEditCancel}
        onRowEditSave={onRowEditSave}
        onRowEditInit={onRowEditInit}
      >
        <Column
          field="name"
          columnKey="name"
          header="Name"
          filter={Boolean(props.values?.length > 5) && !editing}
          filterPlaceholder="Search by name"
          filterMatchMode="custom"
          filterFunction={onNameFilter}
          editor={(props) => inputTextEditor('fields', props, 'name')}
        />
        <Column
          className={editing ? 'rowEditor-editButton-hidden ' : ''}
          rowEditor={props.editable}
          header={props.editable && 'Edit'}
          headerStyle={{ width: '90px' }}
        />
        <Column
          field="remove"
          header={props.editable && 'Remove'}
          headerStyle={{ width: '70px' }}
          body={props.editable && actionBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrialFilters: (actionData) => dispatch(getTrialFilters(actionData)),
    getProductFilters: (actionData) => dispatch(getProductFilters(actionData)),
    getProfile: (actionData) => dispatch(getProfile(actionData)),
    getCropOptions: (actionData) => dispatch(getCropOptions(actionData)),
    getSectionOptions: (actionData) => dispatch(getSectionOptions(actionData)),
    getProfileFieldGroups: (actionData) =>
      dispatch(getProfileFieldGroups(actionData)),
  };
};

export default connect(null, mapDispatchToProps)(ReferenceTable);
